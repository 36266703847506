// angular
import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
import { AuthorizeGuard, RoleGuard } from 'src/api-authorization/authorize.guard';
import { AuthorizeInterceptor } from 'src/api-authorization/authorize.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorInterceptor } from 'src/interceptors/error.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// ngx-bootstrap
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
// npm components
import { AgGridModule } from 'ag-grid-angular';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { NgSelectModule } from '@ng-select/ng-select';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ToastrModule } from 'ngx-toastr';
import { UiSwitchModule } from 'ngx-ui-switch';
// material
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
// services
import { AccountService } from '../services/account.service';
import { AddressService } from '../services/address.service';
import { AppStateService } from 'src/services/app-state.service';
import { AuthenticationService } from '../services/authentication.service';
import { BhaLineService } from 'src/services/bha-line.service';
import { BhaService } from 'src/services/bha.service';
import { BillingCodeService } from 'src/services/billing-code.service';
import { ContactService } from '../services/contact.service';
import { ContractorTypeService } from 'src/services/contractor-type.service';
import { CountyService } from '../services/county.service';
import { CustomerService } from '../services/customer.service';
import { DayService } from 'src/services/day.service';
import { EmployeeService } from '../services/employee.service';
import { FileService } from 'src/services/file.service';
import { FileTypeService } from 'src/services/file-type.service';
import { ItemInstanceLocationHistoryService } from 'src/services/item-instance-location-history.service';
import { InventoryHistoryService } from 'src/services/inventory-history.service';
import { InvoiceItemService } from 'src/services/invoice-item.service';
import { InvoiceService } from '../services/invoice.service';
import { ItemInstanceLocationService } from 'src/services/item-instance-location.service';
import { ItemInstanceService } from 'src/services/item-instance.service';
import { ItemService } from '../services/item.service';
import { ItemTypeService } from 'src/services/item-type.service';
import { ItemVendorService } from 'src/services/item-vendor.service';
import { JobItemInstanceService } from 'src/services/job-item-instance.service';
import { JobSafetyAnalysisService } from 'src/services/job-safety-analysis.service';
import { JobSafetyAnalysisWeatherService } from 'src/services/job-safety-analysis-weather.service';
import { JobSafetyAnalysisWindDirectionService } from 'src/services/job-safety-analysis-wind-direction.service';
import { JobSafetyAnalysisWindSpeedService } from 'src/services/job-safety-analysis-wind-speed.service';
import { JobService } from '../services/job.service';
import { JobStatusService } from 'src/services/job-status.service';
import { JobSubStatusService } from 'src/services/job-sub-status.service';
import { JobTypeService } from '../services/job-type.service';
import { KGridService } from 'src/services/k-grid.service';
import { MaintenanceDurationUomService } from 'src/services/maintenance-duration-uom.service';
import { MaintenanceEventService } from 'src/services/maintenance-event.service';
import { MaintenanceTypeService } from 'src/services/maintenance-type.service';
import { PartFamilyService } from '../services/part-family.service';
import { PartGroupService } from '../services/part-group.service';
import { PaymentTermService } from '../services/payment-term.service';
import { PurchaseOrderLineService } from '../services/purchase-order-line.service';
import { PurchaseOrderService } from '../services/purchase-order.service';
import { PurchaseOrderStatusService } from '../services/purchase-order-status.service';
import { PurchaseOrderTypeService } from '../services/purchase-order-type.service';
import { ReportService } from 'src/services/report.service';
import { RigService } from '../services/rig.service';
import { RouterEventService } from 'src/services/router-event.service';
import { SearchService } from '../services/search.service';
import { ShopService } from '../services/shop.service';
import { StateService } from '../services/state.service';
import { StorageLocationService } from 'src/services/storage-location.service';
import { ThreadTypeService } from 'src/services/thread-type.service';
import { TimezoneService } from 'src/services/timezone.service';
import { UIService } from '../services/ui.service';
import { UnitOfMeasureService } from '../services/unit-of-measure.service';
import { UserService } from '../services/user.service';
import { VendorService } from '../services/vendor.service';
import { WarehouseService } from 'src/services/warehouse.service';
import { WellService } from '../services/well.service';
import { WorkLogService } from 'src/services/work-log.service';
import { WorksheetCategoryService } from 'src/services/worksheet-category.service';
import { WorksheetRowItemTypeService } from 'src/services/worksheet-row-item-type.service';
import { WorksheetRowTypeService } from 'src/services/worksheet-row-type.service';
import { WorksheetService } from 'src/services/worksheet.service';
import { WorksheetSnapshotService } from 'src/services/worksheet-snapshot.service';
import { WorksheetTypeService } from 'src/services/worksheet-type.service';
// components
import { AddBuiltPackerModalComponent } from './packers/add-built-packer-modal/add-built-packer-modal.component';
import { AddEditAddressModalComponent } from './addresses/add-edit-address-modal/add-edit-address-modal.component';
import { AddEditBhaLineModalComponent } from './bha/add-edit-bha-line-modal/add-edit-bha-line-modal.component';
import { AddEditBhaModalComponent } from './bha/add-edit-bha-modal/add-edit-bha-modal.component';
import { AddEditBillingCodeModalComponent } from './billing-codes/add-edit-billing-code-modal/add-edit-billing-code-modal.component';
import { AddEditContactModalComponent } from './contacts/add-edit-contact-modal/add-edit-contact-modal.component';
import { AddEditCustomerModalComponent } from './customers/add-edit-customer-modal/add-edit-customer-modal.component';
import { AddEditEmployeeModalComponent } from './employees/add-edit-employee-modal/add-edit-employee-modal.component';
import { AddEditFileTypeModalComponent } from './file-types/add-edit-file-type-modal/add-edit-file-type-modal.component';
import { AddEditItemInstanceModalComponent } from './item-instances/add-edit-item-instance-modal/add-edit-item-instance-modal.component';
import { AddEditItemInstanceLocationModalComponent }
  from './item-instance-location/add-edit-item-instance-location-modal/add-edit-item-instance-location-modal.component';
import { AddEditItemModalComponent } from './items/add-edit-item-modal/add-edit-item-modal.component';
import { AddEditItemTypeModalComponent } from './item-types/add-edit-item-type-modal/add-edit-item-type-modal.component';
import { AddEditJobFishingDayModalComponent } from './jobs/fishing/add-edit-job-day-fishing-modal/add-edit-job-day-fishing-modal.component';
import { AddEditJobFishingItemInstanceModalComponent }
  from './jobs/fishing/add-edit-job-fishing-item-instance-modal/add-edit-job-fishing-item-instance-modal.component';
import { AddEditJobFishingItemModalComponent } from './jobs/fishing/add-edit-job-fishing-item-modal/add-edit-job-fishing-item-modal.component';
import { AddEditJobFishingItemNoteModalComponent } from './jobs/fishing/add-edit-job-fishing-item-note-modal/add-edit-job-fishing-item-note-modal.component';
import { AddEditJobSalesItemModalComponent } from './jobs/sales/add-edit-job-sales-item-modal/add-edit-job-sales-item-modal.component';
import { AddEditJobSalesItemNoteModalComponent } from './jobs/sales/add-edit-job-sales-item-note-modal/add-edit-job-sales-item-note-modal.component';
import { AddEditJobSalesModalComponent } from './jobs/shared/add-edit-job-sales-modal/add-edit-job-sales-modal.component';
import { AddEditJobStatusModalComponent } from './job-statuses/add-edit-job-status-modal/add-edit-job-status-modal.component';
import { AddEditJobSubStatusModalComponent } from './job-sub-statuses/add-edit-job-sub-status-modal/add-edit-job-sub-status-modal.component';
import { AddEditJobTypeModalComponent } from './job-types/add-edit-job-type-modal/add-edit-job-type-modal.component';
import { AddEditMaintenanceLogModalComponent } from './maintenance-log/add-edit-maintenance-log-modal/add-edit-maintenance-log-modal.component';
import { AddEditPartFamilyModalComponent } from './part-family/add-edit-part-family/add-edit-part-family-modal.component';
import { AddEditPartGroupModalComponent } from './part-family/add-edit-part-group/add-edit-part-group-modal.component';
import { AddEditPoLineModalComponent } from './purchase-orders/add-edit-po-line-modal/add-edit-po-line-modal.component';
import { AddEditPoModalComponent } from './purchase-orders/add-edit-po-modal/add-edit-po-modal.component';
import { AddEditPurchaseOrderStatusModalComponent }
  from './purchase-order-statuses/add-edit-purchase-order-status-modal/add-edit-purchase-order-status-modal.component';
import { AddEditRigModalComponent } from './rigs/add-edit-rig-modal/add-edit-rig-modal.component';
import { AddEditShopModalComponent } from './shops/add-edit-shop-modal/add-edit-shop-modal.component';
import { AddEditStorageLocationModalComponent } from './storage-location/add-edit-storage-location-modal/add-edit-storage-location-modal.component';
import { AddEditThreadTypeModalComponent } from './thread-types/add-edit-thread-type-modal/add-edit-thread-type-modal.component';
import { AddEditUomModalComponent } from './uoms/add-edit-uom-modal/add-edit-uom-modal.component';
import { AddEditUserModalComponent } from './users/add-edit-user-modal/add-edit-user-modal.component';
import { AddEditVendorModalComponent } from './vendors/add-edit-vendor-modal/add-edit-vendor-modal.component';
import { AddEditWarehouseModalComponent } from './warehouses/add-edit-warehouse-modal/add-edit-warehouse-modal.component';
import { AddEditWellModalComponent } from './wells/add-edit-well-modal/add-edit-well-modal.component';
import { AddEditWorkLogModalComponent } from './jobs/shared/add-edit-work-log-modal/add-edit-work-log-modal.component';
import { AddEditWorksheetCategoryModalComponent } from './worksheet-categories/add-edit-worksheet-category-modal/add-edit-worksheet-category-modal.component';
import { AddEditWorksheetRowItemTypeModalComponent }
  from './worksheet-types/add-edit-worksheet-row-item-type-modal/add-edit-worksheet-row-item-type-modal.component';
import { AddEditWorksheetRowTypeModalComponent } from './worksheet-types/add-edit-worksheet-row-type-modal/add-edit-worksheet-row-type-modal.component';
import { AddEditWorksheetTypeModalComponent } from './worksheet-types/add-edit-worksheet-type-modal/add-edit-worksheet-type-modal.component';
import { AddressListComponent } from './addresses/address-list/address-list.component';
import { AppComponent } from './app.component';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { AuthLoginComponent } from './authentication/login/login.component';
import { BillingCodeListComponent } from './billing-codes/list/list.component';
import { BillingCodeSmallListComponent } from './billing-codes/billing-code-sm-list/billing-code-sm-list.component';
import { CancelJobConfirmComponent } from './jobs/shared/cancel-job-confirm-modal/cancel-job-confirm-modal.component';
import { CustomerDetailsComponent } from './customers/details/details.component';
import { CustomerListComponent } from './customers/list/list.component';
import { ChangePasswordModalComponent } from './users/change-password-modal/change-password-modal.component';
import { ChooseWorksheetModalComponent } from './worksheets/choose-worksheet-modal/choose-worksheet-modal.component';
import { ConfirmWorksheetIdModalComponent } from './worksheets/confirm-worksheet-id-modal/confirm-worksheet-id-modal.component';
import { ContactListComponent } from './contacts/contact-list/contact-list.component';
import { CopyJobModalComponent } from './jobs/shared/copy-job-modal/copy-job-modal.component';
import { EmployeeListComponent } from './employees/list/list.component';
import { HomeComponent } from './home/home.component';
import { InventoryHistorySmallListComponent } from './inventory-history/inventory-history-sm-list/inventory-history-sm-list.component';
import { FileTypeListComponent } from './file-types/list/list.component';
import { FishingInvoiceTableComponent } from './jobs/shared/fishing-invoice-table/fishing-invoice-table.component';
import { ItemDetailsComponent } from './items/details/details.component';
import { ItemInstanceDetailsComponent } from './item-instances/details/details.component';
import { ItemInstanceLocationListComponent } from './item-instance-location/list/list.component';
import { ItemInstanceLocationHistorySmallListComponent }
  from './item-instance-location-history/item-instance-location-history-sm-list/item-instance-location-history-sm-list.component';
import { ItemInstanceSmallListComponent } from './item-instances/item-instance-sm-list/item-instance-sm-list.component';
import { ItemListComponent } from './items/list/list.component';
import { ItemTypeListComponent } from './item-types/list/list.component';
import { JobPoDisplayComponent } from './jobs/shared/job-po-display/job-po-display.component';
import { JobsFishingDetailsComponent } from './jobs/fishing/details/details.component';
import { JobsFishingToolRentalDetailsComponent } from './jobs/fishing-tool-rental/details/details.component';
import { JobInfoComponent } from './jobs/shared/job-info/job-info.component';
import { JobsListComponent } from './jobs/list/list.component';
import { JobSafetyAnalysisComponent } from './jobs/shared/job-safety-analysis/job-safety-analysis.component';
import { JobSafetyAnalysisTabsetComponent } from './jobs/shared/job-safety-analysis-tabset/job-safety-analysis-tabset.component';
import { JobsSalesDetailsComponent } from './jobs/sales/details/details.component';
import { JobStatusListComponent } from './job-statuses/list/list.component';
import { JobStatusHistoryPopoverComponent } from './jobs/shared/job-status-history-popover/job-status-history-popover.component';
import { JobSubStatusListComponent } from './job-sub-statuses/list/list.component';
import { JobTypeListComponent } from './job-types/list/list.component';
import { JsaTabHeadingComponent } from './jobs/shared/jsa-tab-heading/jsa-tab-heading.component';
import { LinkPoToJobModalComponent } from './jobs/shared/link-po-to-job-modal/link-po-to-job-modal.component';
import { ListSkeletonComponent } from './skeletons/list-skeleton/list-skeleton.component';
import { MaintenanceLogListComponent } from './maintenance-log/list/list.component';
import { MaintenanceLogSmallListComponent } from './maintenance-log/maintenance-log-sm-list/maintenance-log-sm-list.component';
import { ManualInventoryAdjustmentModalComponent } from './inventory-history/manual-inventory-adjustment-modal/manual-inventory-adjustment-modal.component';
// import { ManualInventoryTransferModalComponent } from './inventory-history/manual-inventory-transfer-modal/manual-inventory-transfer-modal.component';
import { MobileReorderInvoiceModalComponent } from './jobs/shared/mobile-re-order-invoice-modal/mobile-re-order-invoice-modal.component';
import { MobileReorderToolsModalComponent } from './jobs/shared/mobile-re-order-tools-modal/mobile-re-order-tools-modal.component';
import { PartFamilyListComponent } from './part-family/list/list.component';
import { PackerDetailsComponent } from './jobs/packer/details/details.component';
import { PackerListComponent } from './packers/list/list.component';
import { PackerTableComponent } from './packers/packer-table/packer-table.component';
import { PurchaseOrderDetailsComponent } from './purchase-orders/details/details.component';
import { PurchaseOrderListComponent } from './purchase-orders/list/list.component';
import { PurchaseOrderStatusListComponent } from './purchase-order-statuses/list/list.component';
import { ReportListComponent } from './reports/list/list.component';
import { ReportModalComponent } from './reports/report-modal/report-modal.component';
import { RigsSmallListComponent } from './rigs/rigs-sm-list/rigs-sm-list.component';
import { RoleMapModalComponent } from './users/role-map-modal/role-map-modal.component';
import { SalesInvoiceTableComponent } from './jobs/shared/sales-invoice-table/sales-invoice-table.component';
import { SerializedLocationListComponent } from './serialized-locations/list/list.component';
import { ShopListComponent } from './shops/list/list.component';
import { StatusButtonFishingComponent } from './jobs/shared/status-button-fishing/status-button-fishing.component';
import { StatusButtonSalesComponent } from './jobs/shared/status-button-sales/status-button-sales.component';
import { StorageLocationListComponent } from './storage-location/list/list.component';
import { SubStatusButtonComponent } from './jobs/shared/sub-status-button/sub-status-button.component';
import { SubStatusButtonHandsComponent } from './jobs/shared/sub-status-button-hands/sub-status-button-hands.component';
import { SuperAdminEditConfirmModalComponent } from './shared/super-admin-edit-confirm-modal/super-admin-edit-confirm-modal.component';
import { TableSkeletonComponent } from './skeletons/table-skeleton/table-skeleton.component';
import { ThreadTypeListComponent } from './thread-types/list/list.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { UomListComponent } from './uoms/list/list.component';
import { UserListComponent } from './users/list/list.component';
import { VendorItemSmallListComponent } from './items/vendor-item-sm-list/vendor-item-sm-list.component';
import { VendorListComponent } from './vendors/list/list.component';
import { WarehouseListComponent } from './warehouses/list/list.component';
import { WorksheetCategoryListComponent } from './worksheet-categories/list/list.component';
import { WellsSmallListComponent } from './wells/wells-sm-list/wells-sm-list.component';
import { WorkLogComponent } from './jobs/shared/work-log/work-log.component';
import { WorksheetModalComponent } from './worksheets/worksheet-modal/worksheet-modal.component';
import { WorksheetsSmallListComponent } from './worksheets/worksheets-sm-list/worksheets-sm-list.component';
import { WorksheetSnapshotComparisonModalComponent } from './worksheets/worksheet-snapshot-comparison-modal/worksheet-snapshot-comparison-modal.component';
import { WorksheetTypeDetailsComponent } from './worksheet-types/details/details.component';
import { WorksheetTypeListComponent } from './worksheet-types/list/list.component';
// Directives
import { NgxModalDraggableDirective } from '../directives/draggable-modal.directive';
// Pipes
import { CurrencyPipe } from '@angular/common';
import { FilterPipe } from 'src/pipes/filter.pipe';
import { FirstOrDefaultPipe } from 'src/pipes/firstOrDefault.pipe';

@NgModule({
  declarations: [
    AddBuiltPackerModalComponent,
    AddEditAddressModalComponent,
    AddEditBhaLineModalComponent,
    AddEditBhaModalComponent,
    AddEditBillingCodeModalComponent,
    AddEditContactModalComponent,
    AddEditCustomerModalComponent,
    AddEditEmployeeModalComponent,
    AddEditItemInstanceLocationModalComponent,
    AddEditItemInstanceModalComponent,
    AddEditItemModalComponent,
    AddEditItemTypeModalComponent,
    AddEditFileTypeModalComponent,
    AddEditJobFishingDayModalComponent,
    AddEditJobFishingItemInstanceModalComponent,
    AddEditJobFishingItemModalComponent,
    AddEditJobFishingItemNoteModalComponent,
    AddEditJobSalesItemModalComponent,
    AddEditJobSalesItemNoteModalComponent,
    AddEditJobSalesModalComponent,
    AddEditJobStatusModalComponent,
    AddEditJobSubStatusModalComponent,
    AddEditJobTypeModalComponent,
    AddEditMaintenanceLogModalComponent,
    AddEditPartFamilyModalComponent,
    AddEditPartGroupModalComponent,
    AddEditPoModalComponent,
    AddEditPoLineModalComponent,
    AddEditPurchaseOrderStatusModalComponent,
    AddEditRigModalComponent,
    AddEditShopModalComponent,
    AddEditStorageLocationModalComponent,
    AddEditThreadTypeModalComponent,
    AddEditVendorModalComponent,
    AddEditUomModalComponent,
    AddEditUserModalComponent,
    AddEditWarehouseModalComponent,
    AddEditWellModalComponent,
    AddEditWorkLogModalComponent,
    AddEditWorksheetCategoryModalComponent,
    AddEditWorksheetRowTypeModalComponent,
    AddEditWorksheetRowItemTypeModalComponent,
    AddEditWorksheetTypeModalComponent,
    AddressListComponent,
    AppComponent,
    AppLayoutComponent,
    AuthLoginComponent,
    BillingCodeListComponent,
    BillingCodeSmallListComponent,
    CancelJobConfirmComponent,
    ChooseWorksheetModalComponent,
    ConfirmWorksheetIdModalComponent,
    ContactListComponent,
    CopyJobModalComponent,
    CustomerDetailsComponent,
    CustomerListComponent,
    ChangePasswordModalComponent,
    EmployeeListComponent,
    HomeComponent,
    InventoryHistorySmallListComponent,
    ItemDetailsComponent,
    ItemInstanceDetailsComponent,
    ItemInstanceLocationListComponent,
    ItemInstanceLocationHistorySmallListComponent,
    ItemInstanceSmallListComponent,
    ItemListComponent,
    ItemTypeListComponent,
    FileTypeListComponent,
    FilterPipe,
    FirstOrDefaultPipe,
    FishingInvoiceTableComponent,
    JobPoDisplayComponent,
    JobsFishingDetailsComponent,
    JobsFishingToolRentalDetailsComponent,
    JobInfoComponent,
    JobsListComponent,
    JobsSalesDetailsComponent,
    JobSafetyAnalysisComponent,
    JobSafetyAnalysisTabsetComponent,
    JobStatusListComponent,
    JobStatusHistoryPopoverComponent,
    JobSubStatusListComponent,
    JobTypeListComponent,
    JsaTabHeadingComponent,
    LinkPoToJobModalComponent,
    ListSkeletonComponent,
    MaintenanceLogListComponent,
    MaintenanceLogSmallListComponent,
    ManualInventoryAdjustmentModalComponent,
    // ManualInventoryTransferModalComponent,
    MobileReorderInvoiceModalComponent,
    MobileReorderToolsModalComponent,
    NgxModalDraggableDirective,
    PartFamilyListComponent,
    PackerDetailsComponent,
    PackerListComponent,
    PackerTableComponent,
    PurchaseOrderDetailsComponent,
    PurchaseOrderListComponent,
    PurchaseOrderStatusListComponent,
    ReportListComponent,
    ReportModalComponent,
    RigsSmallListComponent,
    RoleMapModalComponent,
    SalesInvoiceTableComponent,
    SerializedLocationListComponent,
    ShopListComponent,
    StatusButtonFishingComponent,
    StatusButtonSalesComponent,
    StorageLocationListComponent,
    SubStatusButtonComponent,
    SubStatusButtonHandsComponent,
    SuperAdminEditConfirmModalComponent,
    TableSkeletonComponent,
    ThreadTypeListComponent,
    TopNavComponent,
    UomListComponent,
    UserListComponent,
    VendorItemSmallListComponent,
    VendorListComponent,
    WarehouseListComponent,
    WellsSmallListComponent,
    WorkLogComponent,
    WorksheetCategoryListComponent,
    WorksheetModalComponent,
    WorksheetsSmallListComponent,
    WorksheetSnapshotComparisonModalComponent,
    WorksheetTypeDetailsComponent,
    WorksheetTypeListComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    ApiAuthorizationModule,
    BrowserAnimationsModule,
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    NgxSkeletonLoaderModule.forRoot(),
    PaginationModule.forRoot(),
    PopoverModule.forRoot(),
    ProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    SortableModule.forRoot(),
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    ToastrModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    SweetAlert2Module.forRoot(),
    UiSwitchModule.forRoot({ size: 'small' }),
    AgGridModule,
    DateInputsModule,
    ExcelModule,
    FormsModule,
    GridModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule.forRoot([
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'login', component: AuthLoginComponent },
      {
        path: '', component: AppLayoutComponent, canActivate: [AuthorizeGuard], children: [
          {
            path: 'home',
            data: { requiredRoles: ['General', 'Fishing Hand', 'Packer Hand'] },
            component: HomeComponent, pathMatch: 'full', canActivate: [AuthorizeGuard, RoleGuard]
          },
          {
            path: '', component: AppLayoutComponent, canActivate: [AuthorizeGuard], children: [
              { path: 'home', component: HomeComponent, pathMatch: 'full', canActivate: [AuthorizeGuard] },
              {
                path: 'billing-codes', data: { requiredRoles: ['General'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: BillingCodeListComponent },
                ]
              },
              {
                path: 'customers', data: { requiredRoles: ['General'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: CustomerListComponent },
                  { path: 'details/:id', component: CustomerDetailsComponent, pathMatch: 'prefix' }
                ]
              },
              {
                path: 'employees', data: { requiredRoles: ['General'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: EmployeeListComponent },
                ]
              },
              {
                path: 'items', data: { requiredRoles: ['General'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: ItemListComponent },
                  { path: 'details/:id', component: ItemDetailsComponent, pathMatch: 'prefix' }
                ]
              },
              {
                path: 'item-instances', data: { requiredRoles: ['General'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: 'details/:id', component: ItemInstanceDetailsComponent, pathMatch: 'prefix' }
                ]
              },
              {
                path: 'item-instance-locations', data: { requiredRoles: ['Super Admin'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: ItemInstanceLocationListComponent },
                ]
              },
              {
                path: 'warehouses', data: { requiredRoles: ['Super Admin'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: WarehouseListComponent },
                ]
              },
              {
                path: 'shops', data: { requiredRoles: ['Super Admin'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: ShopListComponent },
                ]
              },
              {
                path: 'units-of-measure', data: { requiredRoles: ['Super Admin'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: UomListComponent },
                ]
              },
              {
                path: 'item-types', data: { requiredRoles: ['Super Admin'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: ItemTypeListComponent },
                ]
              },
              {
                path: 'file-types', data: { requiredRoles: ['Super Admin'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: FileTypeListComponent },
                ]
              },
              {
                path: 'job-sub-statuses', data: { requiredRoles: ['Super Admin'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: JobSubStatusListComponent },
                ]
              },
              {
                path: 'job-statuses', data: { requiredRoles: ['Super Admin'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: JobStatusListComponent },
                ]
              },
              {
                path: 'job-types', data: { requiredRoles: ['Super Admin'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: JobTypeListComponent },
                ]
              },
              {
                path: 'purchase-order-statuses', data: { requiredRoles: ['Super Admin'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: PurchaseOrderStatusListComponent },
                ]
              },
              {
                path: 'jobs', canActivate: [AuthorizeGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', data: { requiredRoles: ['General'] }, canActivate: [RoleGuard], component: JobsListComponent },
                  {
                    path: 'sales', data: { requiredRoles: ['General'] }, canActivate: [RoleGuard],
                    children: [
                      {
                        path: 'details/:id',
                        data: { requiredRoles: ['General'] },
                        canActivate: [RoleGuard], component: JobsSalesDetailsComponent, pathMatch: 'prefix'
                      }
                    ]
                  },
                  {
                    path: 'fishing', children: [
                      {
                        path: 'details/:id', data: { requiredRoles: ['General', 'Fishing Hand'] },
                        canActivate: [RoleGuard], component: JobsFishingDetailsComponent, pathMatch: 'prefix'
                      }
                    ]
                  },
                  {
                    path: 'fishing-tool-rental', children: [
                      {
                        path: 'details/:id',
                        data: { requiredRoles: ['General'] },
                        canActivate: [RoleGuard], component: JobsFishingToolRentalDetailsComponent, pathMatch: 'prefix'
                      }
                    ]
                  },
                  {
                    path: 'packer', children: [
                      {
                        path: 'details/:id',
                        data: { requiredRoles: ['General', 'Packer Hand'] },
                        canActivate: [RoleGuard],
                        component: PackerDetailsComponent,
                        pathMatch: 'prefix'
                      }
                    ]
                  }
                ]
              },
              {
                path: 'part-families', data: { requiredRoles: ['General'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: PartFamilyListComponent },
                ]
              },
              {
                path: 'packers', data: { requiredRoles: ['General', 'Packer Hand', 'Builder'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: PackerListComponent },
                ]
              },
              {
                path: 'serialized-locations', data: { requiredRoles: ['General'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: SerializedLocationListComponent },
                ]
              },
              {
                path: 'storage-locations', data: { requiredRoles: ['General'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: StorageLocationListComponent },
                ]
              },
              {
                path: 'maintenance-logs', canActivate: [AuthorizeGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: MaintenanceLogListComponent },
                ]
              },
              {
                path: 'purchase-orders', data: { requiredRoles: ['General'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: PurchaseOrderListComponent },
                  { path: 'details/:id', component: PurchaseOrderDetailsComponent, pathMatch: 'prefix' }
                ]
              },
              {
                path: 'reports', data: { requiredRoles: ['General'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: ReportListComponent },
                ]
              },
              {
                path: 'users', data: { requiredRoles: ['Manage Users'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: UserListComponent },
                ]
              },
              {
                path: 'vendors', data: { requiredRoles: ['General'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: VendorListComponent },
                ]
              },
              {
                path: 'thread-types', data: { requiredRoles: ['General'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: ThreadTypeListComponent },
                ]
              },
              {
                path: 'worksheet-categories', data: { requiredRoles: ['General'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: WorksheetCategoryListComponent },
                ]
              },
              {
                path: 'worksheet-types', data: { requiredRoles: ['General'] }, canActivate: [AuthorizeGuard, RoleGuard],
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: WorksheetTypeListComponent },
                  { path: 'details/:id', component: WorksheetTypeDetailsComponent, pathMatch: 'prefix' }
                ]
              },
            ]
          }
        ]
      },
    ], { relativeLinkResolution: 'legacy' })
  ],
  entryComponents: [

  ],
  providers: [
    AccountService,
    AddressService,
    AppStateService,
    AuthenticationService,
    BhaLineService,
    BhaService,
    BillingCodeService,
    ContactService,
    ContractorTypeService,
    CountyService,
    CurrencyPipe,
    CustomerService,
    DayService,
    EmployeeService,
    FileService,
    FileTypeService,
    InventoryHistoryService,
    InvoiceItemService,
    InvoiceService,
    ItemInstanceLocationHistoryService,
    ItemInstanceLocationService,
    ItemInstanceService,
    ItemService,
    ItemTypeService,
    ItemVendorService,
    JobItemInstanceService,
    JobSafetyAnalysisWeatherService,
    JobSafetyAnalysisWindDirectionService,
    JobSafetyAnalysisWindSpeedService,
    JobSafetyAnalysisService,
    JobService,
    JobStatusService,
    JobSubStatusService,
    JobTypeService,
    KGridService,
    MaintenanceDurationUomService,
    MaintenanceEventService,
    MaintenanceTypeService,
    PartFamilyService,
    PartGroupService,
    PaymentTermService,
    PurchaseOrderService,
    PurchaseOrderLineService,
    PurchaseOrderStatusService,
    PurchaseOrderTypeService,
    ReportService,
    RigService,
    RouterEventService,
    SearchService,
    ShopService,
    StateService,
    StorageLocationService,
    ThreadTypeService,
    TimezoneService,
    UnitOfMeasureService,
    UserService,
    UIService,
    VendorService,
    WarehouseService,
    WellService,
    WorkLogService,
    WorksheetRowItemTypeService,
    WorksheetRowTypeService,
    WorksheetService,
    WorksheetCategoryService,
    WorksheetSnapshotService,
    WorksheetTypeService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
