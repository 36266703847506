<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">{{model.name}}</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row"
    *ngIf="model.reportParams&&(model.reportParams.hasStartDate||model.reportParams.hasEndDate)">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"
      *ngIf="model.reportParams.hasStartDate">Start Date</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="model.reportParams.hasStartDate">
      <input type="text"
        placeholder="mm/dd/yyyy"
        class="form-control"
        [(ngModel)]="model.reportParams.startDate"
        required
        bsDatepicker>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"
      *ngIf="model.reportParams.hasEndDate">End Date</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="model.reportParams.hasEndDate">
      <input type="text"
        placeholder="mm/dd/yyyy"
        class="form-control"
        [(ngModel)]="model.reportParams.endDate"
        required
        bsDatepicker>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="model.reportParams&&model.reportParams.hasIncludeOpenToggle">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Include Open</label>
    <div class="col-sm-4 form-group mb-0">
      <ui-switch [(ngModel)]="model.reportParams.includeOpenToggleValue"
        name="includeOpenToggleValue"></ui-switch>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="model.reportParams&&model.reportParams.hasDateDropDown">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Snapshot</label>
    <div class="col-sm-10 form-group mb-0">
      <ng-select [items]="snapshotDates"
        bindLabel="date"
        bindValue="dateString"
        [(ngModel)]="model.reportParams.dropDownDate"
        [clearable]="false"
        name="snapshotDates">
      </ng-select>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="model.reportParams&&model.reportParams.hasJobId">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Jobs</label>
    <div class="col-sm-10 form-group mb-0">
      <ng-select [items]="jobs"
        bindLabel="description"
        bindValue="jobId"
        [(ngModel)]="model.reportParams.jobId"
        [clearable]="false"
        [disabled]="!jobs||jobs.length===0"
        name="jobs">
      </ng-select>
    </div>
  </div>
  <div class="modal-footer"
    *ngIf="model">
    <button type="button"
      class="btn btn-primary"
      (click)="onSave()"
      [disabled]="disableSave || !(model
        && model.reportParams
        && ((model.reportParams.startDate
            &&model.reportParams.endDate
            &&model.reportParams.hasEndDate
            &&model.reportParams.hasStartDate)
            ||
            (model.reportParams.endDate
            &&model.reportParams.hasEndDate)
            || (model.reportParams.hasDateDropDown
                &&model.reportParams.dropDownDate)
            || (model.reportParams.hasJobId
                && model.reportParams.jobId))
        )">
      <i class="fa fa-file-excel"></i>&nbsp;&nbsp;Generate Report
    </button>
    <button type="button"
      class="btn btn-default"
      (click)="bsModalRef.hide()">Cancel</button>
  </div>
