import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ReportModel } from 'src/models/report.model';
import { ReportService } from 'src/services/report.service';
import { TimezoneService } from 'src/services/timezone.service';
import { UIService } from 'src/services/ui.service';
import { DateDropdown } from '../../../models/date-dropdown.model';
import { JobService } from 'src/services/job.service';
import { JobDropdownModel } from 'src/models/job-dropdown.model';

@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['report-modal.component.css']
})

export class ReportModalComponent implements OnInit, OnDestroy {
  public id: number = -1;
  public model: ReportModel;
  public snapshotDates: DateDropdown[] = [];
  public jobs: JobDropdownModel[] = [];
  public disableSave: boolean = false;

  private modelSub: Subscription;
  private snapshotDatesSub: Subscription;
  private saveSub: Subscription;
  private jobSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public jobService: JobService,
    public reportService: ReportService,
    public timeZoneService: TimezoneService,
    public uiService: UIService,
  ) { };

  ngOnDestroy(): void {
    this.jobSub?.unsubscribe();

    this.modelSub?.unsubscribe();

    this.snapshotDatesSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.getModel();
  }

  public getModel(): void {
    this.modelSub = this.reportService.getReport(
      this.id,
      true)
      .subscribe((x: ReportModel) => {
        this.model = x;

        if (this.model.reportParams?.hasDateDropDown) {
          this.getSnapshotDates();
        }

        if (this.model.reportParams && this.model.reportParams.hasStartDate) {
          this.setStartDate();
        }

        if (this.model.reportParams && this.model.reportParams.hasEndDate) {
          this.setEndDate();
        }

        if (this.model.reportParams && this.model.reportParams.hasJobId) {
          this.getJobs();
        }
      });
  }

  public getSnapshotDates(): void {
    this.snapshotDatesSub = this.reportService.getAvailableItemMasterSnapshotDates()
      .subscribe(dates => {
        this.snapshotDates = dates;

        if (dates.length > 0) {
          this.model.reportParams.dropDownDate = dates[0].dateString;
        }
      });
  }

  public getJobs(): void {
    this.jobSub = this.jobService.getJobsWithWorkLogsForDropdown()
      .subscribe(jobs => {
        this.jobs = jobs;
        if (jobs.length > 0) {
          this.model.reportParams.jobId = jobs[0].jobId;
        }
      });
  }

  public onSave(): void {
    this.disableSave = true;

    this.saveSub = this.reportService.generateReport(this.model)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('Report Generated', 'Success');
        } else {
          this.disableSave = false;
          this.uiService.showError('Report Not Generated', 'Error');
        }
      });
  }

  public setStartDate(): void {
    const today = this.timeZoneService.removeTimeFromDate(new Date());

    if (today.getDate() === 1) {
      this.model.reportParams.startDate = this.timeZoneService.getFirstDayOfPreviousMonth(today);
    } else {
      this.model.reportParams.startDate = this.timeZoneService.getFirstDayOfCurrentMonth(today);
    }
  }

  public setEndDate(): void {
    const today = this.timeZoneService.removeTimeFromDate(new Date());

    this.model.reportParams.endDate = today;
  }

}
