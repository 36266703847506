import { Injectable, ViewContainerRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class UIService {
  public vcr: ViewContainerRef;
  constructor(private toastr: ToastrService) { }

  public showSuccess(message: string, title: string) {
    this.toastr.success(message, title);
  }
  public showError(message: string, title: string) {
    this.toastr.error(message, title);
  }

  public showWarning(message: string, title: string) {
    this.toastr.warning(message, title);
  }

  public showInfo(message: string) {
    this.toastr.info(message);
  }

  public showToastSaving(): void {
    this.toastr.info('Please wait while your content saves. <i class="fas fa-spinner fa-spin"></i>',
      'Saving...', { timeOut: 0, extendedTimeOut: 0, enableHtml: true, closeButton: true });
  }

  public showCustom(customHtml: string) {
    // this.toastr.info(//(customHtml, '', { enableHTML: true });
  }

  public killAllToastMessages(): void {
    this.toastr.clear();
  }

}
