import { AddressModel } from './address.model';
import { ContactModel } from './contact.model';

export class CustomerModel {
  customerId: number;
  name: string;
  billingAddressId: number | null;
  billingAddress: AddressModel;
  paymentTermName: string;
  dailyInvoiceMax: number | null;
  customerAddresses: AddressModel[];
  customerContacts: ContactModel[];
  isActive: boolean;
}
