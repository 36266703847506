import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PoFilterParamsModel } from '../models/RequestParams/po-filter-params.model';
import { PurchaseOrderModel } from '../models/purchase-order.model';
import { Router } from '@angular/router';
import { Shops } from '../enums/shops';
import { tap } from 'rxjs/operators';
import { TimezoneService } from './timezone.service';
import { UserService } from './user.service';

@Injectable()
export class PurchaseOrderService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private router: Router,
    private timezoneService: TimezoneService,
    private userService: UserService
  ) { }

  public purchaseOrder: BehaviorSubject<PurchaseOrderModel> = new BehaviorSubject(null);
  public purchaseOrderList: BehaviorSubject<Array<PurchaseOrderModel>> = new BehaviorSubject(null);
  public openPurchaseOrderList: BehaviorSubject<Array<PurchaseOrderModel>> = new BehaviorSubject(null);
  public pendingPurchaseOrderList: BehaviorSubject<Array<PurchaseOrderModel>> = new BehaviorSubject(null);
  public openInventoryLine: BehaviorSubject<[boolean, PurchaseOrderModel]> = new BehaviorSubject([false, null]);
  public openMiscLine: BehaviorSubject<[boolean, PurchaseOrderModel]> = new BehaviorSubject([false, null]);

  public poFilterParams: PoFilterParamsModel = new PoFilterParamsModel();

  public poListIsLoading: boolean = false;
  public Shops = Shops;

  private apiUrl = 'api/PurchaseOrder';  // URL to web api

  refreshPurchaseOrderList(
    showInactive: boolean,
    clear: boolean = false): void {
    if (clear) {
      this.poListIsLoading = true;
    }

    this.getPurchaseOrders(showInactive)
      .subscribe((x: PurchaseOrderModel[]) => {
        this.purchaseOrderList.next(x);
        this.poListIsLoading = false;
      });
  }

  public getPurchaseOrdersForJobLink(
    showInactive: boolean,
    jobId: number): Observable<Array<PurchaseOrderModel>> {
    return this.getPurchaseOrders(showInactive)
      .pipe(tap((x: PurchaseOrderModel[]) => {
        x.forEach((po: PurchaseOrderModel) => {
          po.disabled = po.jobId !== null && po.jobId !== jobId;
          po.dropDownDisplay = `#${po.ponumberDisplay} - ${po.vendorName} - $${po.total}`;
          po.dropDownDisplay += po.disabled ? ` (Invoice# ${po.jobDisplay})` : '';
        });
      }));
  }

  public getPurchaseOrders(showInactive: boolean): Observable<Array<PurchaseOrderModel>> {
    this.poFilterParams.ponumber = this.poFilterParams.ponumber !== null
      && this.poFilterParams.ponumber !== undefined
      && this.poFilterParams.ponumber.trim() !== '' ? this.poFilterParams.ponumber : null;

    return this.http.post<Array<PurchaseOrderModel>>(this.baseUrl + this.apiUrl + '/GetPurchaseOrders/' + showInactive, this.poFilterParams)
      .pipe(tap((x: Array<PurchaseOrderModel>) => {
        x.forEach((po: PurchaseOrderModel) => {
          po.podate = po.podate != null ? new Date(po.podate) : null;
          po.deliveryDate = po.deliveryDate != null ? new Date(po.deliveryDate) : null;
        });
      }));
  }

  public getOpenPurchaseOrders(): Observable<boolean> {
    this.openPurchaseOrderList.next(null);

    return new Observable((observer: Observer<boolean>) => {
      this.http.get<Array<PurchaseOrderModel>>(this.baseUrl + this.apiUrl + '/GetOpenPurchaseOrders')
        .pipe(tap((x: Array<PurchaseOrderModel>) => {
          x.forEach((po: PurchaseOrderModel) => {
            po.podate = po.podate != null ? new Date(po.podate) : null;
            po.deliveryDate = po.deliveryDate != null ? new Date(po.deliveryDate) : null;
          });
        }))
        .subscribe((x: PurchaseOrderModel[]) => {
          this.openPurchaseOrderList.next(x);
          observer.next(true);
        });
    });
  }

  public getPendingPurchaseOrders(): void {
    this.openPurchaseOrderList.next(null);

    this.http.get<Array<PurchaseOrderModel>>(this.baseUrl + this.apiUrl + '/GetPendingPurchaseOrders')
      .pipe(tap((x: Array<PurchaseOrderModel>) => {
        x.forEach((po: PurchaseOrderModel) => {
          po.podate = po.podate != null ? new Date(po.podate) : null;
          po.deliveryDate = po.deliveryDate != null ? new Date(po.deliveryDate) : null;
        });
      }))
      .subscribe((x: PurchaseOrderModel[]) => {
        this.pendingPurchaseOrderList.next(x);
      });
  }

  public refreshPurchaseOrder(id: number): void {
    this.poListIsLoading = true;
    if (this.purchaseOrder.value && id != this.purchaseOrder.value.poid) {
      this.purchaseOrder.next(null);
    }

    this.getPurchaseOrderById(id)
      .subscribe((x: PurchaseOrderModel) => {
        this.purchaseOrder.next(x);
        if (this.openInventoryLine.value !== null
          && this.openInventoryLine.value[0] == true) {
          this.openInventoryLine.next([true, x]);
        };
        if (this.openMiscLine.value !== null
          && this.openMiscLine.value[0] == true) {
          this.openMiscLine.next([true, x]);
        };
        this.poListIsLoading = false;
      });
  }

  public getPurchaseOrderById(id: number): Observable<PurchaseOrderModel> {
    return this.http.get<PurchaseOrderModel>(this.baseUrl + this.apiUrl + '/GetPurchaseOrderById/' + id)
      .pipe(tap((x: PurchaseOrderModel) => {
        x.podate = x.podate != null ? new Date(x.podate) : null;
        x.deliveryDate = x.deliveryDate != null ? new Date(x.deliveryDate) : null;
      }));
  }

  public addEditPurchaseOrder(
    dto: PurchaseOrderModel,
    openInventoryLine: boolean = false,
    openMiscLine: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      const poDate: Date = dto.podate;  // Store because the DTO value is corrected for offset
      this.http.post<PurchaseOrderModel>(this.baseUrl + this.apiUrl + '/AddEditPurchaseOrder', this.cleanPurchaseOrderModel(dto))
        .subscribe((x: PurchaseOrderModel) => {
          if (x !== null && dto.poid !== 0) {
            // PO is not new
            dto.podate = poDate;
            this.purchaseOrder.next(dto);
          } else {
            // PO is new
            this.router.navigateByUrl(`/purchase-orders/details/${x.poid}`);
            this.openInventoryLine.next([openInventoryLine, x]);
            this.openMiscLine.next([openMiscLine, x]);
          }
          observer.next(x !== null);
        });
    });
  }

  public deletePurchaseOrder(
    dto: PurchaseOrderModel,
    showInactive: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeletePurchaseOrder/' + dto.poid)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.refreshPurchaseOrderList(showInactive);
          }
          observer.next(x);
        });
    });
  }

  public generateNewPoModel(): PurchaseOrderModel {
    const user = this.userService.user.value;
    const today = new Date();

    let model = new PurchaseOrderModel();
    model.poid = 0;
    model.podate = today;
    model.deliveryDate = today;
    model.isActive = true;
    model.shopId = user != null ? user.defaultShopId : this.Shops.Williston;

    return model;
  }

  public clearFilterParams(showInactive: boolean) {
    Object.keys(this.poFilterParams).forEach((index: string) => {
      if (Array.isArray(this.poFilterParams[index])) {
        this.poFilterParams[index] = [];
      } else {
        this.poFilterParams[index] = null;
      }
    });

    this.refreshPurchaseOrderList(showInactive);
  }

  public getNumberOfActiveFilterFields(): number {
    let count: number = 0;

    Object.values(this.poFilterParams).forEach((value: any) => {
      count = value ? count += 1 : count;
    });

    return count;
  }

  private cleanPurchaseOrderModel(model: PurchaseOrderModel): PurchaseOrderModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      } else if (model[index] instanceof Date
        && !isNaN(model[index])) {
        model[index] = this.timezoneService.correctOffset(model[index]);
      }
    });

    return model;
  }

}
