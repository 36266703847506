<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="['/home']"
        class="breadcrumb-link-color">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Item Master</span> - Manage</h4>
      </a>
      <button type="button"
        class="btn btn-outline-dark btn-sm ml-3 pl-1 pr-1 pt-0 pb-0 no-border"
        [title]="!showFilter ? 'Show Filter' : 'Hide Filter'"
        (click)="showFilter = !showFilter;getNumberOfActiveFilterFields();">
        <span *ngIf="!showFilter">
          <i class="fa-regular fa-chevrons-right"></i>&nbsp;Show </span>
        <span *ngIf="showFilter"><i class="fa-regular fa-chevrons-left"></i>&nbsp;Hide
        </span>Filter<span class="text-warning"
          *ngIf="!showFilter && numberOfActiveFilterFields > 0"><strong>&nbsp;&nbsp;({{numberOfActiveFilterFields}}
            active
            criteria)</strong></span></button>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Item Master</a>
        <span class="breadcrumb-item active">List</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-lg-3"
          *ngIf="showFilter">
          <div class="card">
            <div class="card-header bg-light header-elements-inline"
              [ngClass]="{'pr-2 pl-2':isTablet}">
              <h6 class="card-title">Filter Criteria</h6>
              <div class="switch-container pt-2 mr-2">
                <span class="switch-label">Show Inactive </span>
                <ui-switch [ngModel]="showInactive"
                  (change)="onChangeShowInactive($event)"></ui-switch>
              </div>
            </div>
            <div class="card-body"
              [ngClass]="{'pr-2 pl-2':isTablet}">
              <div class="form-group row">
                <label class="col-form-label col-lg-4 col-md-2">Part #</label>
                <div class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}">
                  <input type="text"
                    class="form-control"
                    [(ngModel)]="itemService.itemFilterParams.partNumber"
                    name="ponumberFilter"
                    (keyup.enter)="itemService.getItems(showInactive, true, false, true, true);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })">
                </div>
                <label class="col-form-label col-lg-4 col-md-2 mt-lg-3 mt-sm-0"
                  [ngClass]="{'smaller-text':isTablet}">Description</label>
                <div class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}">
                  <input type="text"
                    class="form-control"
                    [(ngModel)]="itemService.itemFilterParams.description"
                    name="ponumberFilter"
                    (keyup.enter)="itemService.getItems(showInactive, true, false, true, true);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-lg-4 col-md-2">Primary Vendor</label>
                <div class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="vendorService.vendorList | async; let vend">
                  <ng-select [items]="vend"
                    bindLabel="name"
                    bindValue="vendorId"
                    [(ngModel)]="itemService.itemFilterParams.vendorId"
                    name="vendors">
                  </ng-select>
                </div>
                <div *ngIf="!vendorService.vendorList.value"
                  class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}">
                  <ng-select name="vendors"
                    [readonly]="true">
                  </ng-select>
                </div>
                <label class="col-form-label col-lg-4 col-md-2 mt-lg-3 mt-sm-0">UOM</label>
                <div class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="unitsOfMeasure">
                  <ng-select [items]="unitsOfMeasure"
                    bindLabel="name"
                    bindValue="uomId"
                    [(ngModel)]="itemService.itemFilterParams.uomId"
                    name="uom">
                  </ng-select>
                </div>
                <div *ngIf="!unitsOfMeasure"
                  class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}">
                  <ng-select name="uom"
                    [readonly]="true">
                  </ng-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-lg-4 col-md-2">Part Family</label>
                <div class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="partFamilyService.partFamilyList | async; let pf">
                  <ng-select [items]="pf"
                    bindLabel="name"
                    bindValue="partFamilyId"
                    [(ngModel)]="itemService.itemFilterParams.partFamilyId"
                    (change)="onChangePartFamily($event)"
                    name="partFamily"
                    appendTo='body'>
                  </ng-select>
                </div>
                <div *ngIf="!partFamilyService.partFamilyList.value"
                  class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}">
                  <ng-select name="partFamily"
                    [readonly]="true">
                  </ng-select>
                </div>
                <label class="col-form-label col-lg-4 col-md-2 mt-lg-3 mt-sm-0">Part Group</label>
                <div class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="partGroupService.partGroupList | async; let pg">
                  <ng-select [items]="pg"
                    bindLabel="name"
                    bindValue="partGroupId"
                    [(ngModel)]="itemService.itemFilterParams.partGroupId"
                    name="partGroup"
                    appendTo='body'>
                  </ng-select>
                </div>
                <div class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="(partGroupService.partGroupList | async)===null; let pg">
                  <input type="text"
                    class="form-control"
                    readonly>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-lg-4 col-md-2">Item Type</label>
                <div class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="itemTypes">
                  <ng-select [items]="itemTypes"
                    bindLabel="name"
                    bindValue="itemTypeId"
                    [(ngModel)]="itemService.itemFilterParams.itemTypeId"
                    name="types"
                    appendTo="body">
                  </ng-select>
                </div>
                <div *ngIf="!itemTypes"
                  class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}">
                  <ng-select name="types"
                    [readonly]="true">
                  </ng-select>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <button type="submit"
                    class="btn btn-block btn-primary"
                    (click)="itemService.getItems(showInactive, true, false, true, true);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })"><i
                      class="fa fa-search mr-2"></i>Search</button>
                </div>
                <div class="col-sm-6">
                  <button type="submit"
                    class="btn btn-block btn-light"
                    (click)="itemService.clearFilterParams(showInactive);partGroupService.partGroupList.next(null);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })"><i
                      class="far fa-times-circle"></i>&nbsp;Clear</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div [ngClass]="{'col-lg-9': showFilter, 'col-lg-12': !showFilter}">
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;">
            <div class="card-header bg-light header-elements-sm-inline">
              <h6 class="card-title">Items</h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm"
                  (click)="openItemModal(0)">
                  <i class="fa fa-plus"></i> New Item
                </button>
              </div>
            </div>
            <app-table-skeleton *ngIf="itemService.itemListIsLoading"></app-table-skeleton>
            <ng-container *ngIf="!itemService.itemListIsLoading">
              <div class="table-responsive"
                *ngIf="itemService.itemList | async; let model">
                <kendo-grid style="min-width:1200px;"
                  [kendoGridBinding]="model"
                  filterable="false"
                  [sortable]="true"
                  [resizable]="true"
                  [rowClass]="rowCallback"
                  [style.maxHeight.px]="650"
                  [pageSize]="kGridService.kGridParams.pageSize"
                  [skip]="kGridService.kGridParams.skip"
                  (pageChange)="kGridService.pageChange($event)"
                  [pageable]="{
                              buttonCount: kGridService.kGridParams.buttonCount,
                              info: kGridService.kGridParams.info,
                              type: kGridService.kGridParams.type,
                              pageSizes: kGridService.kGridParams.pageSizes,
                              previousNext: kGridService.kGridParams.previousNext
                            }">
                  <ng-template kendoGridToolbarTemplate>
                    <button type="button"
                      kendoGridExcelCommand
                      icon="file-excel">Export to Excel</button>
                  </ng-template>
                  <kendo-grid-column field="one"
                    title=""
                    [width]="60"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <a class="btn btn-xs btn-light"
                        [routerLink]="['/items/details', dataItem.itemId]"><i class="fa fa-search"></i></a>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="partNumber"
                    title="Part #"
                    [width]="90">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      {{dataItem.partNumber}}<span *ngIf="dataItem.itemTypeId === ItemTypes.ThirdParty"
                        class="text-primary"
                        title="Third-Party Rental">&nbsp;<i class="fas fa-registered"></i></span>
                      <span *ngIf="!dataItem.isActive"
                        class="text-danger"
                        title="Inactive">&nbsp;<i class="fas fa-times-circle"></i></span>
                      <span *ngIf="dataItem.itemTypeId === ItemTypes.Build"
                        class="text-secondary"
                        title="Build">&nbsp;<i class="fa-solid fa-wrench"></i></span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="description"
                    title="Description">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <ng-template #popoverTemplateBc>
                        <div class="pl-2 pr-2">
                          <div *ngFor="let ws of dataItem.worksheets;let i = index"
                            class="row">
                            <div class="col-xs-12">
                              <a [routerLink]="['/worksheet-types/details', ws.worksheetTypeId]"
                                target="_blank">{{ws.name}}</a>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                      <div class="d-flex justify-content-between">
                        <span>{{dataItem.description}}</span>
                        <span *ngIf="dataItem.worksheets && dataItem.worksheets.length > 0"
                          class="text-primary pointer">
                          &nbsp;&nbsp;
                          <i class="fal fa-file-alt"
                            type="button"
                            [popover]="popoverTemplateBc"
                            popoverTitle="Active Worksheets"
                            placement="auto"
                            [outsideClick]="true"
                            container="body">
                          </i>
                        </span>
                      </div>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="vendorName"
                    title="Primary Vendor"
                    [width]="165">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span *ngIf="dataItem.vendorName">{{dataItem.vendorName}}</span>
                      <span *ngIf="!dataItem.vendorName"
                        class="text-muted">n/a</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="uomName"
                    title="UOM"
                    [width]="80">
                  </kendo-grid-column>
                  <kendo-grid-column field="partFamilyName"
                    title="Part Family"
                    [width]="120">
                  </kendo-grid-column>
                  <kendo-grid-column field="partGroupName"
                    title="Part Group"
                    [width]="120">
                  </kendo-grid-column>
                  <kendo-grid-column field="itemTypeName"
                    title="Type"
                    [width]="65">
                  </kendo-grid-column>
                  <kendo-grid-column field="totalInventoryCount"
                    title="Inventory"
                    [width]="90"
                    [style]="{'text-align': 'right'}">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <ng-template #popoverTemplateInv>
                        <div class="pl-2 pr-2">
                          <div *ngFor="let si of dataItem.shopItems;let i = index"
                            class="row">
                            <div class="col-xs-9">
                              {{si.name}}:
                            </div>
                            <div class="col-xs-3">
                              <span class="float-right">&nbsp;<strong>{{si.inventoryCount}}</strong></span>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                      <ng-template #popoverTemplateBuildInv>
                        <div class="pl-2 pr-2">
                          <div *ngFor="let b of dataItem.inStockBuilds;let i = index"
                            class="row">
                            <div class="col-xs-3">
                              {{b.worksheetId}}
                            </div>
                            <div class="col-xs-9">
                              <span class="float-right">&nbsp;-&nbsp;{{b.notes}}</span>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                      <span *ngIf="dataItem.itemTypeId === ItemTypes.Sale">
                        {{dataItem.totalInventoryCount}}&nbsp;&nbsp;
                        <span class="text-primary pointer">
                          <i class="fas fa-info-circle"
                            type="button"
                            [popover]="popoverTemplateInv"
                            popoverTitle="Inventory"
                            placement="auto"
                            [outsideClick]="true"
                            container="body"></i>
                        </span>
                      </span>
                      <span *ngIf="dataItem.itemTypeId === ItemTypes.Build">
                        {{dataItem.inStockBuilds.length}}&nbsp;&nbsp;
                        <span class="text-primary pointer">
                          <i class="fas fa-info-circle"
                            type="button"
                            [popover]="popoverTemplateBuildInv"
                            popoverTitle="Build Inventory"
                            placement="auto"
                            [outsideClick]="true"
                            container="body"></i>
                        </span>
                      </span>
                      <span *ngIf="dataItem.itemTypeId !== ItemTypes.Sale && dataItem.itemTypeId !== ItemTypes.Build"
                        class="text-muted">n/a</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="totalReserveCount"
                    title="Reserve"
                    [width]="90"
                    [style]="{'text-align': 'right'}">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <ng-template #popoverTemplateRes>
                        <div class="pl-2 pr-2">
                          <div *ngFor="let si of dataItem.shopItems;let i = index"
                            class="row">
                            <div class="col-xs-9">
                              {{si.name}}:
                            </div>
                            <div class="col-xs-3">
                              <span class="float-right">&nbsp;<strong>{{si.reserveCount}}</strong></span>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                      <span *ngIf="dataItem.itemTypeId === ItemTypes.Sale">
                        {{dataItem.totalReserveCount}}&nbsp;&nbsp;
                        <span class="text-primary pointer">
                          <i class="fas fa-info-circle"
                            type="button"
                            [popover]="popoverTemplateRes"
                            popoverTitle="Reserve"
                            placement="auto"
                            [outsideClick]="true"
                            container="body"></i>
                        </span>
                      </span>
                      <span *ngIf="dataItem.itemTypeId !== ItemTypes.Sale"
                        class="text-muted">n/a</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="onJobSerializableCount"
                    title="Rental Inv."
                    [width]="90"
                    [style]="{'text-align': 'right'}">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <ng-template #popoverTemplateRes>
                        <div class="pl-2 pr-2">
                          <div class="row">
                            <div class="col-xs-9">
                              On Job:
                            </div>
                            <div class="col-xs-3">
                              <span
                                class="float-right">&nbsp;<strong>{{dataItem.onJobSerializableCount}}</strong></span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xs-9">
                              In Shop:
                            </div>
                            <div class="col-xs-3">
                              <span
                                class="float-right">&nbsp;<strong>{{dataItem.inShopSerializableCount}}</strong></span>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                      <span *ngIf="dataItem.itemTypeId === ItemTypes.Rental">
                        {{dataItem.inShopSerializableCount + dataItem.onJobSerializableCount}}&nbsp;&nbsp;
                        <span class="text-primary pointer">
                          <i class="fas fa-info-circle"
                            type="button"
                            [popover]="popoverTemplateRes"
                            popoverTitle="Rental Inventory"
                            placement="auto"
                            [outsideClick]="true"
                            container="body"></i>
                        </span>
                      </span>
                      <span
                        *ngIf="dataItem.itemTypeId === ItemTypes.Sale || dataItem.itemTypeId === ItemTypes.Build || dataItem.itemTypeId === ItemTypes.ThirdParty"
                        class="text-muted">n/a</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="cost"
                    title="Cost"
                    [width]="90"
                    [style]="{'text-align': 'right'}">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      {{dataItem.cost | currency}}
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="one"
                    title=""
                    [width]="60"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <button class="btn btn-xs btn-light"
                        [swal]="{ title: 'Delete Item?', text: dataItem.partNumber + ' - ' + dataItem.description, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                        (confirm)="deleteItem(dataItem.itemId)"
                        [disabled]="dataItem.isActive === false">
                        <i class="fa fa-trash"></i>
                      </button>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-excel fileName="Grizzly - Item Master.xlsx"
                    [fetchData]="allData">
                    <kendo-excelexport-column field="partNumber"
                      title="Part #">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="description"
                      title="Description">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="itemTypeName"
                      title="Item Type">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="vendorName"
                      title="Primary Vendor">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="uomName"
                      title="UOM">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="partFamilyName"
                      title="Part Family">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="partGroupName"
                      title="Part Group">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="totalInventoryCount"
                      title="Inventory">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="totalReserveCount"
                      title="Reserve">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="totalSerializableCount"
                      title="Rental Inv.">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="cost"
                      title="Cost">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="isActive"
                      title="Active">
                    </kendo-excelexport-column>
                  </kendo-grid-excel>
                </kendo-grid>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
