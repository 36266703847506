<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">{{isNew ? 'Add' : 'Edit'}}&nbsp;Customer</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row">
    <label for="title"
      class="col-sm-3 text-right control-label col-form-label">Name</label>
    <div class="col-sm-9 form-group mb-0">
      <input type="text"
        class="form-control form-control-sm"
        id="name"
        [(ngModel)]="model.name"
        name="name"
        required>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-3 text-right control-label col-form-label">Terms</label>
    <div class="col-sm-9 form-group mb-0"
      *ngIf="terms">
      <ng-select [items]="terms"
        bindLabel="name"
        bindValue="paymentTermId"
        [(ngModel)]="model.paymentTermId"
        name="terms">
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-3 text-right control-label col-form-label">Daily Invoice Max.</label>
    <div class="col-sm-9 form-group mb-0">
      <div class="input-group input-group-sm">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input type="number"
          class="form-control form-control-sm"
          id="dailyInvoiceMax"
          [(ngModel)]="model.dailyInvoiceMax"
          name="dailyInvoiceMax">
      </div>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="!isNew">
    <label for="title"
      class="col-sm-3 text-right control-label col-form-label">Billing Address</label>
    <div class="col-sm-9 form-group mb-0"
      *ngIf="addressService.addressList | async; let addr">
      <ng-select [items]="addr"
        bindLabel="addressString"
        bindValue="addressId"
        [(ngModel)]="model.billingAddressId"
        [disabled]="addr.length===0"
        name="addresses">
      </ng-select>
    </div>
    <div class="col-sm-4"
      *ngIf="(addressService.addressList | async)===null; let addr">
      <input type="text"
        class="form-control"
        readonly>
    </div>
  </div>
</div>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="!(model.name) 
      || disableSave">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
