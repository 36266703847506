<!-- Multiple fixed navBars wrapper -->
<div class="fixed-top">
  <!-- Main navbar -->
  <div class="navbar navbar-expand-md navbar-dark"
    [ngClass]="{'top-nav-test' : isTest}">
    <div class="navbar-brand wmin-0 mr-3"
      style="padding: 0;">
      <a [routerLink]="['/']"
        class="d-inline-block">
        <img src="/images/toolbox_hor_logo.png"
          alt=""
          style="height: 40px; margin-top: 3px; margin-left: 3px;">
      </a>
    </div>
    <div class="d-md-none">
      <button class="navbar-toggler"
        type="button"
        (click)="topIsCollapsed = !topIsCollapsed"
        aria-expanded="false">
        <i class="fa fa-bars"></i>
      </button>
    </div>
    <!--SEARCH-->
    <form class="custom-search-form"
      style="color: #fff; margin-left: 10px;"
      *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
      <mat-form-field class="example-full-width"
        style="position: relative; top: 5px;">
        <input #searchInput
          matInput
          placeholder="{{searchPlaceHolder}}"
          aria-label="Search"
          [matAutocomplete]="auto"
          [formControl]="searchCtrl"
          [(ngModel)]="searchTerm"
          (keyup)="getSearchResults($event)"
          (blur)="clearSearchResults($event)"
          (focus)="onFocus($event)">
        <mat-autocomplete #auto="matAutocomplete"
          (optionSelected)="goToSelection($event.option.value)"
          autoActiveFirstOption
          [displayWith]="displayFn"
          panelWidth="true">
          <h6 *ngIf="!searchTerm || (searchTerm && searchTerm.length < 2)"
            class="mb-1 mt-1 text-center text-primary font-weight-bold">Recent Search Results</h6>
          <mat-option *ngFor="let result of searchResults | async"
            [value]="result"
            class="result-display">
            <div><small><i [ngClass]="result.icon"></i>&nbsp;{{result.name}}</small></div>
            <div><small style="font-size:.7em;">{{result.description}}</small></div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <ng-container *ngIf="advancedSearch">
        <div *ngIf="partGroupService.itemInstancePartGroupList | async; let pg"
          class="search-button-margin inline-block select-width">
          <ng-select [items]="pg"
            bindLabel="name"
            bindValue="partGroupId"
            [(ngModel)]="partGroupId"
            (change)="onChangeOfPartGroup($event)"
            name="partGroups"
            [clearable]="false"
            class="w-100">
          </ng-select>
        </div>
      </ng-container>
      <button type="button"
        class="btn btn-secondary btn-sm search-button-margin pl-1 pr-1"
        [title]="!advancedSearch ? 'Show Advanced Search by Serial #' : 'Hide Advanced Search by Serial #'"
        (click)="toggleAdvancedSearch($event)">
        <span *ngIf="!advancedSearch">
          <i class="fa-regular fa-chevrons-right"></i><span *ngIf="!isTablet">&nbsp;Show</span></span>
        <span *ngIf="advancedSearch"><i class="fa-regular fa-chevrons-left"></i><span
            *ngIf="!isTablet">&nbsp;Hide</span>
        </span><span *ngIf="!isTablet || (isTablet && !advancedSearch)">&nbsp;Advanced</span><span *ngIf="!isTablet">
          Search</span></button>
      <br>
    </form>
    <div class="pl-5 pt-2"
      *ngIf="isTest && (!isTablet || (isTablet && !advancedSearch))">
      <h4 class="text-light mb-0">TEST</h4>
    </div>
    <!--SEARCH-->
    <div class="collapse navbar-collapse"
      id="navbar-mobile"
      [collapse]="topIsCollapsed">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown dropdown-user"
          dropdown
          *ngIf="userService.user | async; let user">
          <a dropdownToggle
            class="navbar-nav-link d-flex align-items-center dropdown-toggle">
            <i class="fa fa-user-circle"
              style="font-size: 22px; margin-right: 10px;"></i>
            <span *ngIf="!isTablet || (isTablet && !advancedSearch)">{{user.firstName}} {{user.lastName}}</span>
          </a>
          <div *dropdownMenu
            class="dropdown-menu dropdown-menu-right"
            role="menu">
            <a (click)="openPasswordModal()"
              class="dropdown-item"><i class="fal fa-lock-open-alt"></i> Change Password</a>
            <a [routerLink]='["/authentication/logout"]'
              [state]='{ local: true }'
              class="dropdown-item"><i class="icon-switch2"></i> Logout</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- /main navbar -->
  <!-- Secondary navbar -->
  <div class="navbar navbar-expand-md navbar-light">
    <div class="text-center d-md-none w-100">
      <button type="button"
        class="navbar-toggler dropdown-toggle"
        (click)="isCollapsed = !isCollapsed">
        <i class="icon-unfold mr-2"></i>
        Navigation
      </button>
    </div>
    <div class="collapse navbar-collapse"
      id="navbar-navigation"
      [collapse]="isCollapsed">
      <div class="navbar-collapse">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}">
            <a class="navbar-nav-link"
              [routerLink]="['/']">
              <i class="fal fa-tachometer-alt"></i>
              &nbsp;Dashboard
            </a>
          </li>
          <li class="nav-item dropdown"
            #dropdown="bs-dropdown"
            dropdown
            routerLinkActive="active"
            *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
            <a class="navbar-nav-link dropdown-toggle"
              dropdownToggle
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              <i class="fal fa-file-invoice mr-2"></i>
              Invoices
            </a>
            <div class="dropdown-menu"
              aria-labelledby="navbarDropdown"
              *dropdownMenu
              routerLinkActive="active">
              <a class="dropdown-item"
                (click)="openJobModal(0, JobTypes.Sales)"><i class="fal fa-truck-loading"></i>Create Sales Invoice</a>
              <a class="dropdown-item"
                (click)="openJobModal(0,JobTypes.Sales, true)"><i class="fal fa-quote-right"></i>Create Sales Quote</a>
              <a class="dropdown-item"
                (click)="openJobModal(0, JobTypes.Fishing)"><i class="fal fa-fish"></i>Create Fishing Invoice</a>
              <a class="dropdown-item"
                (click)="openJobModal(0, JobTypes.FishingToolRental)"><i class="fal fa-tools"></i>Create Fishing Tool
                Rental Invoice</a>
              <a class="dropdown-item"
                (click)="openJobModal(0, JobTypes.Packer)"><i class="fal fa-screwdriver"></i>Create Packer
                Invoice</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item"
                [routerLink]="['/jobs/list']"><i class="fal fa-search"></i>View All</a>
            </div>
          </li>
          <li class="nav-item dropdown"
            #dropdown="bs-dropdown"
            dropdown
            routerLinkActive="active"
            *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
            <a class="navbar-nav-link dropdown-toggle"
              dropdownToggle
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              <i class="fal fa-file-invoice mr-2"></i>
              Purchase Orders
            </a>
            <div class="dropdown-menu"
              aria-labelledby="navbarDropdown"
              *dropdownMenu>
              <a class="dropdown-item"
                (click)="createPoModal()"><i class="fal fa-plus"></i>Create New PO</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item"
                [routerLink]="['/purchase-orders/list']"><i class="fal fa-search"></i>View All</a>
            </div>
          </li>
          <li class="nav-item dropdown"
            #dropdown="bs-dropdown"
            dropdown
            routerLinkActive="active"
            *ngIf="userService.user.value && userService.user.value.activeRoles && (userService.user.value.activeRoles.includes('General') || userService.user.value.activeRoles.includes('Packer Hand'))">
            <a class="navbar-nav-link dropdown-toggle"
              dropdownToggle
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              <i class="fal fa-list mr-2"></i>
              Builds
            </a>
            <div class="dropdown-menu"
              aria-labelledby="navbarDropdown"
              *dropdownMenu>
              <a class="dropdown-item"
                (click)="openChooseWorksheetModal()"><i class="fal fa-user-helmet-safety"></i>Create Build</a>
              <a class="dropdown-item"
                [routerLink]="['/packers']"><i class="fal fa-list"></i>Build List</a>
            </div>
          </li>
          <li class="nav-item"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}">
            <a class="navbar-nav-link"
              [routerLink]="['/maintenance-logs']">
              <i class="fal fa-hammer"></i>
              &nbsp;Maintenance
            </a>
          </li>
          <li class="nav-item dropdown"
            #dropdown="bs-dropdown"
            dropdown
            routerLinkActive="active"
            *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
            <a class="navbar-nav-link dropdown-toggle"
              dropdownToggle
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              <i class="fal fa-cogs mr-2"></i>
              Administration
            </a>
            <div class="dropdown-menu"
              aria-labelledby="navbarDropdown"
              *dropdownMenu>
              <a class="dropdown-item"
                [routerLink]="['/billing-codes']"><i class="fal fa-barcode-alt"></i>Billing Codes</a>
              <a class="dropdown-item"
                [routerLink]="['/customers']"><i class="fal fa-users"></i>Customers</a>
              <a class="dropdown-item"
                [routerLink]="['/employees']"><i class="fal fa-person-dolly"></i>Employees</a>
              <a class="dropdown-item"
                [routerLink]="['/users']"
                *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Manage Users')"><i
                  class="fal fa-user-lock"></i>Users</a>
              <a class="dropdown-item"
                [routerLink]="['/items']"><i class="fal fa-cog"></i>Items</a>
              <a class="dropdown-item"
                [routerLink]="['/part-families']"><i class="fal fa-cogs"></i>Part Families/Groups</a>
              <a class="dropdown-item"
                [routerLink]="['/serialized-locations']"><i class="fal fa-map-marker-alt"></i>Serialized Item Location
                Quick-View</a>
              <a class="dropdown-item"
                [routerLink]="['/thread-types']"><i class="fal fa-screwdriver"></i>Thread Types</a>
              <a class="dropdown-item"
                [routerLink]="['/vendors']"><i class="fal fa-briefcase"></i>Vendors</a>
              <a class="dropdown-item"
                [routerLink]="['/worksheet-types']"><i class="fal fa-list-alt"></i>Worksheet Types</a>
              <a class="dropdown-item"
                [routerLink]="['/worksheet-categories']"><i class="fal fa-file-alt"></i>Worksheet Categories</a>
              <a class="dropdown-item"
                [routerLink]="['/reports']"><i class="fal fa-file-chart-line"></i>Reports</a>
            </div>
          </li>
          <li class="nav-item dropdown"
            #dropdown="bs-dropdown"
            dropdown
            routerLinkActive="active"
            *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')">
            <a class="navbar-nav-link dropdown-toggle"
              dropdownToggle
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              <i class="fal fa-user-lock mr-2"></i>
              Super Admin
            </a>
            <div class="dropdown-menu"
              aria-labelledby="navbarDropdown"
              *dropdownMenu>
              <a class="dropdown-item"
                [routerLink]="['/file-types']"
                *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')"><i
                  class="fal fa-file"></i>File Types</a>
              <a class="dropdown-item"
                [routerLink]="['/item-types']"
                *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')"><i
                  class="fal fa-keyboard"></i>Item Types</a>
              <a class="dropdown-item"
                [routerLink]="['/job-statuses']"
                *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')"><i
                  class="fal fa-bars"></i>Job Statuses</a>
              <a class="dropdown-item"
                [routerLink]="['/job-sub-statuses']"
                *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')"><i
                  class="fal fa-th-list"></i>Job Sub Statuses</a>
              <a class="dropdown-item"
                [routerLink]="['/job-types']"
                *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')"><i
                  class="fal fa-user-hard-hat"></i>Job Types</a>
              <a class="dropdown-item"
                [routerLink]="['/purchase-order-statuses']"
                *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')"><i
                  class="fal fa-list-alt"></i>Purchase Order Statuses</a>
              <a class="dropdown-item"
                [routerLink]="['/item-instance-locations']"
                *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')"><i
                  class="fal fa-location"></i>Serialized
                Item Locations</a>
              <a class="dropdown-item"
                [routerLink]="['/shops']"
                *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')"><i
                  class="fal fa-store"></i>Shops</a>
              <a class="dropdown-item"
                [routerLink]="['/warehouses']"
                *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')"><i
                  class="fal fa-warehouse"></i>Warehouses</a>
              <a class="dropdown-item"
                [routerLink]="['/units-of-measure']"
                *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')"><i
                  class="fal fa-ruler-triangle"></i>Units Of Measure</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- /secondary navbar -->
</div>
