import { PurchaseOrderLineModel } from './purchase-order-line.model';

export class PurchaseOrderModel {
  poid: number;
  vendorId: number;
  vendorName: string;
  podate: Date;
  ponumber: number;
  ponumberNew: number;
  ponumberDisplay: string;
  shipMethod: string;
  shipTerms: string;
  deliveryDate: Date;
  description: string;
  ponotes: string;
  shopId: number;
  shopName: string;
  accountId: number;
  accountName: string;
  isActive: boolean;

  vendorPaymentTerms: string;

  poLines: Array<PurchaseOrderLineModel>;
  jobId: number;
  jobDisplay: string;
  jobTypeId: number;
  derivedStatusId: number;
  derivedStatusName: string;
  total: number;
  numberOfItems: number;
  dropDownDisplay: string;
  disabled: boolean = false;

  createdByName: string;
}
