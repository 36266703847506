import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateDropdown } from '../models/date-dropdown.model';
import { ReportModel } from '../models/report.model';

@Injectable()
export class ReportService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public reportList: BehaviorSubject<Array<ReportModel>> = new BehaviorSubject(null);

  private apiUrl = 'api/Report';  // URL to web api

  public generateReport(dto: ReportModel): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<any>(this.baseUrl + this.apiUrl + '/GenerateReport', dto,
        { observe: 'response', responseType: 'blob' as 'json' })
        .subscribe((x: HttpResponse<Blob>) => {
          if (x) {
            this.openReport(x);
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
    });
  }

  public getAllFishingInvoicePDF(jobId: number): void {
    this.http.post<any>(this.baseUrl + this.apiUrl + '/GetMultipleFishingInvoicePDF/' + jobId, '',
      { observe: 'response', responseType: 'blob' as 'json' })
      .subscribe((x: HttpResponse<any>) => {
        this.openReport(x);
      });
  }

  public getAvailableItemMasterSnapshotDates(): Observable<DateDropdown[]> {
    return this.http.get<Date[]>(this.baseUrl + this.apiUrl + '/GetAvailableItemMasterSnapshotDates')
      .pipe(map((dates: Date[]) => dates.map((date: Date) => new DateDropdown(new Date(date).toLocaleString(), date.toString()))));
  }

  public getBhaPDF(
    bhaId: number,
    jobId: number): void {
    this.http.post<any>(this.baseUrl + this.apiUrl + '/GetBhaPDF/' + bhaId + '/' + jobId, '',
      { observe: 'response', responseType: 'blob' as 'json' })
      .subscribe((x: HttpResponse<any>) => {
        this.openReport(x);
      });
  }

  public getJsaPDF(jobId: number): void {
    this.http.post<any>(this.baseUrl + this.apiUrl + '/GetJsaPDF/' + jobId, '',
      { observe: 'response', responseType: 'blob' as 'json' })
      .subscribe((x: HttpResponse<any>) => {
        this.openReport(x);
      });
  }

  public getFishingInvoicePDF(
    jobId: number,
    invoiceId: number): void {
    this.http.post<any>(this.baseUrl + this.apiUrl + '/GetFishingInvoicePDF/' + jobId + '/' + invoiceId, '',
      { observe: 'response', responseType: 'blob' as 'json' })
      .subscribe((x: HttpResponse<any>) => {
        this.openReport(x);
      });
  }

  public getPackerInvoicePDF(jobId: number): void {
    this.http.post<any>(this.baseUrl + this.apiUrl + '/GetPackerInvoicePDF/' + jobId, '',
      { observe: 'response', responseType: 'blob' as 'json' })
      .subscribe((x: HttpResponse<any>) => {
        this.openReport(x);
      });
  }

  public getPurchaseOrderPDF(poId: number): void {
    this.http.post<any>(this.baseUrl + this.apiUrl + '/GetPurchaseOrderPDF/' + poId, '',
      { observe: 'response', responseType: 'blob' as 'json' })
      .subscribe((x: HttpResponse<any>) => {
        this.openReport(x);
      });
  }

  public getReport(
    id: number,
    noSql: boolean): Observable<ReportModel> {
    return this.http.get<ReportModel>(this.baseUrl + this.apiUrl + '/GetReport/' + id + '/' + noSql);
  }

  public getReports(clear: boolean = false): void {
    if (clear) {
      this.http.get<Array<ReportModel>>(this.baseUrl + this.apiUrl + '/GetReports')
        .subscribe((x: ReportModel[]) => {
          this.reportList.next(x);
        });
    }
  }

  public getSalesInvoicePDF(jobId: number): void {
    this.http.post<any>(this.baseUrl + this.apiUrl + '/GetSalesInvoicePDF/' + jobId, '',
      { observe: 'response', responseType: 'blob' as 'json' })
      .subscribe((x: HttpResponse<any>) => {
        this.openReport(x);
      });
  }

  private openReport(x: HttpResponse<Blob>): void {
    let resp = x.headers.get('content-disposition');
    let fileName = resp !== undefined && resp !== null ? resp.split(';')[1].split('filename')[1].split('=')[1].trim() : null;
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(x.body);
    link.download = fileName;
    link.click();
    link.remove();
  }

}
