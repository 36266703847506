import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ItemDropdownModel } from '../models/item-dropdown.model';
import { ItemFilterParamsModel } from 'src/models/RequestParams/item-filter-params.model';
import { ItemModel } from '../models/item.model';
import { ItemTypes } from 'src/enums/item-types';
import { tap } from 'rxjs/operators';
import { ItemVendorModel } from 'src/models/item-vendor.model';

@Injectable()
export class ItemService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public itemList: BehaviorSubject<Array<ItemModel>> = new BehaviorSubject(null);
  public rentalItemList: BehaviorSubject<Array<ItemModel>> = new BehaviorSubject(null);
  public serializedItemList: BehaviorSubject<Array<ItemModel>> = new BehaviorSubject(null);
  public itemDropdownList: BehaviorSubject<Array<ItemDropdownModel>> = new BehaviorSubject(null);
  public itemWithMaintenanceLogsDropdown: BehaviorSubject<Array<ItemDropdownModel>> = new BehaviorSubject(null);
  public item: BehaviorSubject<ItemModel> = new BehaviorSubject(null);
  public itemCache: BehaviorSubject<ItemModel> = new BehaviorSubject(null);
  public itemFilterParams: ItemFilterParamsModel = new ItemFilterParamsModel();
  public itemListIsLoading: boolean = false;
  public ItemTypes = ItemTypes;


  private apiUrl = 'api/Item';  // URL to web api

  public addEditItem(
    dto: ItemModel,
    showInactive: boolean,
    cacheItem: boolean = false,
    showThirdParty: boolean = false,
    refreshDropdownList: boolean = false): Observable<boolean> {
    if (dto.itemId === 0) {
      dto.partNumber = null;
    }
    // part num will generated on the backend if new
    dto.itemVendor.forEach((iv: ItemVendorModel) => {
      iv = this.cleanItemVendorModel(iv);
    });

    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/AddEditItem', this.cleanItemModel(dto))
        .subscribe((x: number) => {
          if (x !== -1) {
            if (cacheItem) {
              if (!refreshDropdownList) {
                this.getItems(showInactive, true, true, showThirdParty);
              } else {
                this.getItemsDropdown(showInactive, showThirdParty, false);
              }
              dto.itemId = x;
              this.itemCache.next(dto);
            } else {
              this.refreshItem(x);

              if (!refreshDropdownList) {
                this.getItems(showInactive, true, true, showThirdParty);
              } else {
                this.getItemsDropdown(showInactive, showThirdParty, false);
              }
            }
          } else {
            this.itemCache.value !== null ? this.itemCache.next(null) : null;
          }
          observer.next(x !== -1);
        });
    });
  }

  public deleteItem(
    id: number,
    showInactive: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteItem/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getItems(showInactive, true);
          }
          observer.next(x);
        });
    });
  }

  public getItems(
    showInactive: boolean,
    clear: boolean = false,
    clearParams: boolean = false,
    showThirdParty: boolean = false,
    showSerialized: boolean = false,
    showSkeleton: boolean = false): void {
    this.itemFilterParams.partNumber = this.itemFilterParams.partNumber !== null
      && this.itemFilterParams.partNumber !== undefined
      && this.itemFilterParams.partNumber.trim() !== '' ? this.itemFilterParams.partNumber : null;

    this.itemFilterParams.description = this.itemFilterParams.description !== null
      && this.itemFilterParams.description !== undefined
      && this.itemFilterParams.description.trim() !== '' ? this.itemFilterParams.description : null;

    if (!this.itemList.value || clear) {
      if (showSkeleton) {
        this.itemListIsLoading = true;
      }

      this.http.post<Array<ItemModel>>(this.baseUrl + this.apiUrl + '/GetItems/' + showInactive + '/' + showThirdParty + '/' + showSerialized,
        clearParams === true ? new ItemFilterParamsModel() : this.itemFilterParams)
        .pipe(tap((x: Array<ItemModel>) => {
          x.forEach((item: ItemModel) => {
            item.dropdownDisplay = `${item.partNumber} (${item.description})`;
            item.totalInventoryCount = this.ItemTypes.Build === item.itemTypeId ?
              (item.inStockBuilds ?
                item.inStockBuilds.length
                : item.totalInventoryCount)
              :
              item.totalInventoryCount;
            item.totalSerializableCount =
              item.itemTypeId === this.ItemTypes.Rental ?
                ((item.inShopSerializableCount != null ? item.inShopSerializableCount : 0)
                  + (item.onJobSerializableCount != null ? item.onJobSerializableCount : 0))
                : null;
          });
        }))
        .subscribe((x: ItemModel[]) => {
          this.itemList.next(x);
          this.itemListIsLoading = false;
        });
    }
  }

  public getItemsDropdown(
    showInactive: boolean,
    showThirdParty: boolean = false,
    showSerialized: boolean = false,
    showBuilds: boolean = false): void {
    this.http.get<Array<ItemDropdownModel>>(
      this.baseUrl + this.apiUrl + '/GetItemsDropdown/' + showInactive + '/' + showThirdParty + '/' + showSerialized + '/' + showBuilds)
      .pipe(tap((x: Array<ItemDropdownModel>) => {
        x.forEach((item: ItemDropdownModel) => {
          item.dropdownDisplay = `${item.partNumber} (${item.description})`;
        });
      }))
      .subscribe((x: ItemDropdownModel[]) => {
        this.itemDropdownList.next(x);
      });
  }

  public getThirdPartyRentalItems(): Observable<Array<ItemModel>> {
    return this.http.get<Array<ItemModel>>(this.baseUrl + this.apiUrl + '/GetThirdPartyRentalItems');
  }

  public getItemsWithRepairLogsDropdown(itemInstanceId: number = null): void {
    let query = '';

    if (itemInstanceId) {
      query = `?itemInstanceId=${itemInstanceId}`;
    }

    this.http.get<Array<ItemDropdownModel>>(this.baseUrl + this.apiUrl + '/GetItemsWithRepairLogsDropdown' + query)
      .pipe(tap((x: Array<ItemDropdownModel>) => {
        x.forEach((item: ItemDropdownModel) => {
          item.dropdownDisplay = `${item.partNumber} (${item.description})`;
        });
      }))
      .subscribe((x: Array<ItemDropdownModel>) => {
        this.itemWithMaintenanceLogsDropdown.next(x);
      });
  }

  public refreshThirdPartyRentalList(): void {
    this.getThirdPartyRentalItems()
      .pipe(tap((x: ItemModel[]) => {
        x.forEach((item: ItemModel) => {
          item.dropdownDisplay = `${item.partNumber} (${item.description})`;
        });
      }))
      .subscribe((x: ItemModel[]) => {
        this.rentalItemList.next(x);
      });
  }

  public getSerializedItems(): Observable<Array<ItemModel>> {
    return this.http.get<Array<ItemModel>>(this.baseUrl + this.apiUrl + '/GetSerializedItems');
  }

  public refreshSerializedItemList(): void {
    this.getSerializedItems()
      .pipe(tap((x: ItemModel[]) => {
        x.forEach((item: ItemModel) => {
          item.dropdownDisplay = `${item.partNumber} (${item.description})`;
        });
      }))
      .subscribe((x: ItemModel[]) => {
        this.serializedItemList.next(x);
      });
  }

  public refreshItem(id: number): void {
    this.getItemById(id)
      .subscribe((item: ItemModel) => {
        this.item.next(item);
      });
  }

  public getItemById(id: number): Observable<ItemModel> {
    return this.http.get<ItemModel>(this.baseUrl + this.apiUrl + '/GetItemById/' + id)
      .pipe(tap((item: ItemModel) => {
        item.dropdownDisplay = `${item.partNumber} (${item.description})`;
      }));
  }

  public refreshItems(showInactive: boolean): void {
    this.clearFilterParams(showInactive);
  }

  public checkForDeactivatedItem(itemId: number): void {
    if (this.itemList.value !== null) {
      let temp: ItemModel[] = this.itemList.value.filter((x: ItemModel) => {
        return x.itemId === itemId;
      });

      if (temp.length === 0) {
        this.retrieveDeactivatedItem(itemId);
      }
    }
  }

  public retrieveDeactivatedItem(itemId: number): void {
    this.getItemById(itemId)
      .subscribe((x: ItemModel) => {
        if (x !== null) {
          x.dropdownDisplay = `${x.partNumber} (${x.description})`;
          if (this.itemList.value !== null) {
            let temp: ItemModel[] = [x, ...this.itemList.value];
            // temp.unshift(x);
            this.itemList.next(temp);
          }
        }
      });
  }

  public clearFilterParams(showInactive: boolean): void {
    Object.keys(this.itemFilterParams).forEach((index: string) => {
      this.itemFilterParams[index] = null;
    });

    this.getItems(showInactive, true, true, true, true);
  }

  public generateNewItemModel(): ItemModel {
    let model: ItemModel = new ItemModel();
    model.itemId = 0;
    model.isActive = true;
    model.partNumber = 'New Item';
    model.itemVendor = [];
    model.itemTypeId = this.ItemTypes.Sale;

    return model;
  }

  public convertItemToDropdown(item: ItemModel): ItemDropdownModel {
    let newDropdown = new ItemDropdownModel();
    newDropdown.itemId = item.itemId;
    newDropdown.description = item.description;
    newDropdown.dropdownDisplay = item.dropdownDisplay;
    newDropdown.isActive = item.isActive;
    newDropdown.partNumber = item.partNumber;
    newDropdown.shopItems = item.shopItems;
    newDropdown.totalInventoryCount = item.totalInventoryCount;
    newDropdown.totalReserveCount = item.totalReserveCount;
    newDropdown.uomId = item.uomId;

    return newDropdown;
  }

  public getNumberOfActiveFilterFields(): number {
    let count: number = 0;

    Object.values(this.itemFilterParams).forEach((value: any) => {
      count = value ? count += 1 : count;
    });

    return count;
  }

  private cleanItemVendorModel(model: ItemVendorModel): ItemVendorModel {
    model.partNumber = model.partNumber
      && model.partNumber.trim() !== '' ?
      model.partNumber.trim()
      : null;

    model.description = model.description
      && model.description.trim() !== '' ?
      model.description.trim()
      : null;

    return model;
  }

  private cleanItemModel(model: ItemModel): ItemModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

}
