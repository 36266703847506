import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ReportModel } from 'src/models/report.model';
import { KGridService } from 'src/services/k-grid.service';
import { ReportService } from 'src/services/report.service';
import { UIService } from 'src/services/ui.service';
import { ReportModalComponent } from '../report-modal/report-modal.component';

@Component({
  selector: 'app-reports-list',
  templateUrl: './list.component.html',
  styleUrls: ['list.component.css']
})

export class ReportListComponent implements OnInit, OnDestroy {
  @Input() type: string;
  public numberOfSkeletons: number[] = [];

  private noParamReportSub: Subscription;

  constructor(
    private modalService: BsModalService,
    public kGridService: KGridService,
    public uiService: UIService,
    public reportService: ReportService

  ) {
    this.numberOfSkeletons = [...Array(9).keys()];
  };

  public bsModalRef: BsModalRef;

  ngOnDestroy(): void {
    this.noParamReportSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.reportService.getReports(true);
  }

  public openReportModal(reportId: number): void {
    const initialState = {
      id: reportId
    };

    this.bsModalRef = this.modalService.show(ReportModalComponent, { initialState, backdrop: 'static' });
  }

  public generateNoParamReport(model: ReportModel): void {
    this.noParamReportSub = this.reportService.generateReport(model)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Report Generated', 'Success');
        } else {
          this.uiService.showError('Report Not Generated', 'Error');
        }
      });
  }

}
