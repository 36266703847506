<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Home</span> - Dashboard</h4>
      <button type="button"
        class="btn btn-outline-dark btn-sm ml-3 pl-1 pr-1 pt-0 pb-0 no-border"
        [title]="!showFilter ? 'Show Filter' : 'Hide Filter'"
        (click)="showFilter = !showFilter;getNumberOfActiveFilterFields();">
        <span *ngIf="!showFilter">
          <i class="fa-regular fa-chevrons-right"></i>&nbsp;Show </span>
        <span *ngIf="showFilter"><i class="fa-regular fa-chevrons-left"></i>&nbsp;Hide
        </span>Links<span class="text-warning"
          *ngIf="!showFilter && numberOfActiveFilterFields > 0"><strong>&nbsp;&nbsp;({{numberOfActiveFilterFields}}
            active
            criteria)</strong></span></button>
      <!-- <button (click)="zeroOutDicksonInventory()"
        class="ml-2">Zero Out</button> -->
    </div>
    <!-- PLEASE LEAVE THIS HERE FOR PRICE CHANGES -->
    <!-- <button class="btn btn-danger"
      (click)="recalculateJobTotals()">Recalculate Job Totals</button> -->
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Home</a>
        <span class="breadcrumb-item active">Dashboard</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-lg-3 p-lg-0"
          *ngIf="showFilter"
          [ngClass]="{'col-md-12': isHand && isTablet}">
          <div class="row">
            <div class=" col-md-6 col-lg-12">
              <div class="card">
                <div class="card-header bg-primary"
                  [ngClass]="{'pt-2': isHand && isTablet, 'pb-2': isHand && isTablet}">
                  <h5 class="card-title"
                    style="margin-bottom: 0; height: 14px; line-height: 17px;">Quick Actions</h5>
                </div>
                <ul class="list-group list-group-flush border-top"
                  [ngClass]="{'pt-0 pb-0 list-group-horizontal': isHand && isTablet}"
                  id="ul_1">
                  <a (click)="openJobModal(0, JobTypes.Sales)"
                    class="list-group-item list-group-item-action pointer"
                    *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
                    <span class="font-weight-semibold">
                      <i class="fal fa-truck-loading mr-2"></i>
                      Create Sales Invoice / Delivery
                    </span>
                  </a>
                  <a (click)="openJobModal(0, JobTypes.Sales, true)"
                    class="list-group-item list-group-item-action pointer"
                    *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
                    <span class="font-weight-semibold">
                      <i class="fal fa-quote-right mr-2"></i>
                      Create Sales Quote
                    </span>
                  </a>
                  <a (click)="openJobModal(0, JobTypes.Fishing)"
                    class="list-group-item list-group-item-action pointer"
                    [ngClass]="{'border-0': isHand && isTablet}"
                    *ngIf="userService.user.value && userService.user.value.activeRoles && (userService.user.value.activeRoles.includes('General') || userService.user.value.activeRoles.includes('Fishing Hand'))">
                    <span class="font-weight-semibold">
                      <i class="fal fa-fish mr-2"></i>
                      Create Fishing Invoice
                    </span>
                  </a>
                  <a (click)="openJobModal(0, JobTypes.FishingToolRental)"
                    class="list-group-item list-group-item-action pointer"
                    *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
                    <span class="font-weight-semibold">
                      <i class="fal fa-tools mr-2"></i>
                      Create Fishing Tool Rental Invoice
                    </span>
                  </a>
                  <a (click)="openJobModal(0, JobTypes.Packer)"
                    class="list-group-item list-group-item-action pointer"
                    [ngClass]="{'border-0': isHand && isTablet}"
                    *ngIf="userService.user.value && userService.user.value.activeRoles && (userService.user.value.activeRoles.includes('General') || userService.user.value.activeRoles.includes('Packer Hand'))">
                    <span class="font-weight-semibold">
                      <i class="fal fa-screwdriver mr-2"></i>
                      Create Packer Invoice
                    </span>
                  </a>
                  <ul class="list-group list-group-flush border-top p-0"
                    id="ul_2"
                    *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
                    <a (click)="createPoModal()"
                      class="list-group-item list-group-item-action pointer">
                      <span class="font-weight-semibold">
                        <i class="fal fa-file-invoice-dollar mr-2"></i>
                        Purchase Parts / Supplies
                      </span>
                    </a>
                  </ul>
                  <ul class="list-group list-group-flush border-top p-0"
                    id="ul_3"
                    *ngIf="userService.user.value && userService.user.value.activeRoles && (userService.user.value.activeRoles.includes('General'))">
                    <a (click)="openChooseWorksheetModal()"
                      class="list-group-item list-group-item-action pointer"
                      *ngIf="userService.user.value && userService.user.value.activeRoles && (userService.user.value.activeRoles.includes('General') || userService.user.value.activeRoles.includes('Packer Hand'))">
                      <span class="font-weight-semibold">
                        <i class="fal fa-user-helmet-safety mr-2"></i>
                        Create Build
                      </span>
                    </a>
                    <a [routerLink]="['/packers']"
                      class="list-group-item list-group-item-action pointer"
                      *ngIf="userService.user.value && userService.user.value.activeRoles && (userService.user.value.activeRoles.includes('General') || userService.user.value.activeRoles.includes('Packer Hand'))">
                      <span class="font-weight-semibold">
                        <i class="fal fa-list mr-2"></i>
                        Build List
                      </span>
                    </a>
                    <a [routerLink]="['/maintenance-logs']"
                      class="list-group-item list-group-item-action pointer">
                      <span class="font-weight-semibold">
                        <i class="fal fa-hammer mr-2"></i>
                        Maintenance
                      </span>
                    </a>
                  </ul>
                </ul>
              </div>
            </div>
            <div class="col-md-6 col-lg-12">
              <div class="card"
                *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
                <div class="card-header bg-secondary">
                  <h5 class="card-title"
                    style="margin-bottom: 0; height: 14px; line-height: 17px;">Administration</h5>
                </div>
                <ul class="list-group list-group-flush border-top">
                  <a [routerLink]="['/billing-codes']"
                    class="list-group-item list-group-item-action">
                    <span class="font-weight-semibold">
                      <i class="fal fa-barcode-alt mr-2"></i>
                      Billing Codes
                    </span>
                  </a>
                  <a [routerLink]="['/customers']"
                    class="list-group-item list-group-item-action">
                    <span class="font-weight-semibold">
                      <i class="fal fa-users mr-2"></i>
                      Customers
                    </span>
                  </a>
                  <a [routerLink]="['/employees']"
                    class="list-group-item list-group-item-action">
                    <span class="font-weight-semibold">
                      <i class="fal fa-person-dolly mr-2"></i>
                      Employees
                    </span>
                  </a>
                  <a [routerLink]="['/users']"
                    class="list-group-item list-group-item-action"
                    *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Manage Users')">
                    <span class="font-weight-semibold">
                      <i class="fal fa-user-lock mr-2"></i>
                      Users
                    </span>
                  </a>
                  <a [routerLink]="['/items']"
                    class="list-group-item list-group-item-action">
                    <span class="font-weight-semibold">
                      <i class="fal fa-cog mr-2"></i>
                      Items
                    </span>
                  </a>
                  <a [routerLink]="['/part-families']"
                    class="list-group-item list-group-item-action">
                    <span class="font-weight-semibold">
                      <i class="fal fa-cogs mr-2"></i>
                      Part Families/Groups
                    </span>
                  </a>
                  <a [routerLink]="['/serialized-locations']"
                    class="list-group-item list-group-item-action">
                    <span class="font-weight-semibold">
                      <i class="fal fa-map-marker-alt mr-2"></i>
                      <span [ngClass]="{'smaller-text':isTablet}">Serialized Item Location Quick-View</span>
                    </span>
                  </a>
                  <a [routerLink]="['/thread-types']"
                    class="list-group-item list-group-item-action">
                    <span class="font-weight-semibold">
                      <i class="fal fa-screwdriver mr-2"></i>
                      Thread Types
                    </span>
                  </a>
                  <a [routerLink]="['/vendors']"
                    class="list-group-item list-group-item-action">
                    <span class="font-weight-semibold">
                      <i class="fal fa-briefcase mr-2"></i>
                      Vendors
                    </span>
                  </a>
                  <a [routerLink]="['/worksheet-types']"
                    class="list-group-item list-group-item-action">
                    <span class="font-weight-semibold">
                      <i class="fal fa-list-alt mr-2"></i>
                      Worksheet Types
                    </span>
                  </a>
                  <a [routerLink]="['/worksheet-categories']"
                    class="list-group-item list-group-item-action">
                    <span class="font-weight-semibold">
                      <i class="fal fa-file-alt mr-2"></i>
                      Worksheet Categories
                    </span>
                  </a>
                  <a [routerLink]="['/reports']"
                    class="list-group-item list-group-item-action">
                    <span class="font-weight-semibold">
                      <i class="fal fa-file-chart-line mr-2"></i>
                      Reports
                    </span>
                  </a>
                </ul>
              </div>
            </div>
            <div class="col-md-6 col-lg-12">
              <div class="card"
                *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')">
                <div class="card-header bg-secondary">
                  <h5 class="card-title"
                    style="margin-bottom: 0; height: 14px; line-height: 17px;">Super Admin</h5>
                </div>
                <ul class="list-group list-group-flush border-top">
                  <a [routerLink]="['/file-types']"
                    class="list-group-item list-group-item-action"
                    *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')">
                    <span class="font-weight-semibold">
                      <i class="fal fa-file mr-2"></i>
                      <span [ngClass]="{'smaller-text':isTablet}">File Types</span>
                    </span>
                  </a>
                  <a [routerLink]="['/item-types']"
                    class="list-group-item list-group-item-action"
                    *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')">
                    <span class="font-weight-semibold">
                      <i class="fal fa-keyboard mr-2"></i>
                      <span [ngClass]="{'smaller-text':isTablet}">Item Types</span>
                    </span>
                  </a>
                  <a [routerLink]="['/job-statuses']"
                    class="list-group-item list-group-item-action"
                    *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')">
                    <span class="font-weight-semibold">
                      <i class="fal fa-bars mr-2"></i>
                      <span [ngClass]="{'smaller-text':isTablet}">Job Statuses</span>
                    </span>
                  </a>
                  <a [routerLink]="['/job-sub-statuses']"
                    class="list-group-item list-group-item-action"
                    *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')">
                    <span class="font-weight-semibold">
                      <i class="fal fa-th-list mr-2"></i>
                      <span [ngClass]="{'smaller-text':isTablet}">Job Sub Statuses</span>
                    </span>
                  </a>
                  <a [routerLink]="['/job-types']"
                    class="list-group-item list-group-item-action"
                    *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')">
                    <span class="font-weight-semibold">
                      <i class="fal fa-user-hard-hat mr-2"></i>
                      <span [ngClass]="{'smaller-text':isTablet}">Job Types</span>
                    </span>
                  </a>
                  <a [routerLink]="['/purchase-order-statuses']"
                    class="list-group-item list-group-item-action"
                    *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')">
                    <span class="font-weight-semibold">
                      <i class="fal fa-list-alt mr-2"></i>
                      <span [ngClass]="{'smaller-text':isTablet}">Purchase Order Statuses</span>
                    </span>
                  </a>
                  <a [routerLink]="['/item-instance-locations']"
                    class="list-group-item list-group-item-action"
                    *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')">
                    <span class="font-weight-semibold">
                      <i class="fal fa-location mr-2"></i>
                      <span [ngClass]="{'smaller-text':isTablet}">Serialized Item Locations</span>
                    </span>
                  </a>
                  <a [routerLink]="['/shops']"
                    class="list-group-item list-group-item-action"
                    *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')">
                    <span class="font-weight-semibold">
                      <i class="fal fa-store mr-2"></i>
                      Shops
                    </span>
                  </a>
                  <a [routerLink]="['/warehouses']"
                    class="list-group-item list-group-item-action"
                    *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')">
                    <span class="font-weight-semibold">
                      <i class="fal fa-warehouse mr-2"></i>
                      Warehouses
                    </span>
                  </a>
                  <a [routerLink]="['/units-of-measure']"
                    class="list-group-item list-group-item-action"
                    *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')">
                    <span class="font-weight-semibold">
                      <i class="fal fa-ruler-triangle mr-2"></i>
                      Units Of Measure
                    </span>
                  </a>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-9"
          [ngClass]="{'col-lg-12 pl-0': ((isHand && isTablet) || !showFilter)}">
          <!-- BEGIN CARD -->
          <ng-container *ngIf="
          userService.user.value
          &&
          userService.user.value.activeRoles
          &&
          userService.user.value.activeRoles.includes('General')">
            <ng-container *ngIf="userService.user | async; let user">
              <div *ngIf="user.poLimit > 0"
                class="card border-top-2 border-top-primary rounded-top-0"
                style="border-top-color: #2196F3 !important;">
                <div class="card-header bg-light header-elements-sm-inline">
                  <h6 class="card-title">Purchase Orders Requiring Approval</h6>
                </div>
                <!-- BEGIN TABLE -->
                <div class="table-responsive"
                  *ngIf="poService.pendingPurchaseOrderList | async; let model">
                  <kendo-grid [kendoGridBinding]="model"
                    filterable="false"
                    [sortable]="true"
                    [resizable]="true"
                    style="min-width:1200px;">
                    <kendo-grid-column field="one"
                      title=""
                      [width]="60"
                      filterable="false">
                      <ng-template kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex">
                        <a class="btn btn-xs btn-light"
                          [routerLink]="['/purchase-orders/details', dataItem.poid]"><i class="fa fa-search"></i></a>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="ponumberDisplay"
                      title="PO#"
                      [width]="90">
                    </kendo-grid-column>
                    <kendo-grid-column field="podate"
                      title="Date"
                      [width]="90">
                      <ng-template kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex">
                        {{dataItem.podate | date:'shortDate'}}
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="description"
                      title="Description">
                    </kendo-grid-column>
                    <kendo-grid-column field="vendorName"
                      title="Vendor"
                      [width]="200">
                    </kendo-grid-column>
                    <kendo-grid-column field="total"
                      title="Total"
                      [width]="120">
                      <ng-template kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex">
                        {{dataItem.total | currency}}
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="derivedStatusName"
                      title="Status"
                      [width]="150">
                    </kendo-grid-column>
                    <kendo-grid-column field="createdByName"
                      title="Created By"
                      [width]="120">
                    </kendo-grid-column>
                  </kendo-grid>
                </div>
                <!-- END TABLE -->
              </div>
            </ng-container>
          </ng-container>
          <!-- END CARD -->
          <!-- BEGIN CARD -->
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;">
            <div class="card-header bg-light header-elements-sm-inline">
              <h6 class="card-title">Open Invoices</h6>
              <div class="switch-container pt-2 mr-2"
                *ngIf="!isHand">
                <ng-select [items]="jobSubStatuses"
                  bindLabel="name"
                  bindValue="jobSubStatusId"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  [searchable]="false"
                  placeholder="Select Sub Status"
                  [(ngModel)]="userService.openJobUserSubStatuses"
                  name="subStatuses"
                  class="mr-3 multi-select"
                  (change)="onChangeOfSubStatusMulti()">
                </ng-select>
                <span class="switch-label">Show Not Ready To Invoice&nbsp;</span>
                <ui-switch [ngModel]="showNotReadyToInvoice"
                  (change)="onChangeNotReadyToInvoice($event)"></ui-switch>
              </div>
            </div>
            <!-- BEGIN TABLE -->
            <app-table-skeleton [numberToIterate]="5"
              *ngIf="jobService.openJobsAreLoading"></app-table-skeleton>
            <ng-container *ngIf="!jobService.openJobsAreLoading">
              <div class="table-responsive"
                *ngIf="jobService.openJobList | async; let model">
                <kendo-grid [kendoGridBinding]="model"
                  [style.maxHeight.px]="600"
                  filterable="false"
                  [sortable]="true"
                  [resizable]="true"
                  [ngClass]="{'kendo-min-width': (!isTablet || (isTablet && !isHand))}"
                  [rowClass]="!isHand ? rowCallback : rowCallbackClear"
                  style="min-width:1300px;">
                  <kendo-grid-column field="one"
                    title=""
                    [width]="60"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <a class="btn btn-xs btn-light"
                        [routerLink]="['/jobs/sales/details', dataItem.jobId]"
                        *ngIf="dataItem.jobTypeName.toLowerCase() === 'sales'"><i class="fa fa-search"></i></a>
                      <a class="btn btn-xs btn-light"
                        [routerLink]="['/jobs/fishing/details', dataItem.jobId]"
                        *ngIf="dataItem.jobTypeName.toLowerCase() === 'fishing'"><i class="fa fa-search"></i></a>
                      <a class="btn btn-xs btn-light"
                        [routerLink]="['/jobs/fishing-tool-rental/details', dataItem.jobId]"
                        *ngIf="dataItem.jobTypeName.toLowerCase() === 'fishing tool rental'"><i
                          class="fa fa-search"></i></a>
                      <a class="btn btn-xs btn-light"
                        [routerLink]="['/jobs/packer/details', dataItem.jobId]"
                        *ngIf="dataItem.jobTypeName.toLowerCase() === 'packer'"><i class="fa fa-search"></i></a>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="jobNumber"
                    title="Invoice#"
                    [width]="85">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span>{{dataItem.jobNumber}}</span>
                      <span *ngIf='dataItem.notReadyToInvoice && !isHand'
                        class="text-danger"
                        title="Not Ready To Invoice">&nbsp;&nbsp;<i class="fa-solid fa-circle-exclamation"></i></span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="jobDate"
                    title="Date"
                    [width]="90">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      {{dataItem.jobDate | date:'shortDate'}}
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="jobTypeName"
                    title="Job Type"
                    [width]="120">
                  </kendo-grid-column>
                  <kendo-grid-column field="jobSubStatusName"
                    title="Sub Status"
                    [width]="100">
                  </kendo-grid-column>
                  <kendo-grid-column field="wellName"
                    title="Well Site"
                    [width]="200">
                  </kendo-grid-column>
                  <kendo-grid-column field="customerName"
                    title="Customer"
                    [width]="200">
                  </kendo-grid-column>
                  <kendo-grid-column field="orderedByName"
                    title="Ordered By"
                    [width]="200">
                  </kendo-grid-column>
                  <kendo-grid-column field="countyName"
                    title="County"
                    [width]="100">
                  </kendo-grid-column>
                  <kendo-grid-column field="total"
                    title="Total"
                    [width]="120"
                    *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span *ngIf='dataItem.totalStamp !== null'>{{dataItem.totalStamp | currency}}</span>
                      <span *ngIf='dataItem.totalStamp === null'>{{0 | currency}}</span>
                    </ng-template>
                  </kendo-grid-column>
                </kendo-grid>
              </div>
            </ng-container>
            <!-- END TABLE -->
          </div>
          <!-- END CARD -->
          <!-- BEGIN CARD -->
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;"
            *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
            <div class="card-header bg-light header-elements-sm-inline">
              <h6 class="card-title">Open Purchase Orders</h6>
            </div>
            <!-- BEGIN TABLE -->
            <app-table-skeleton *ngIf="!openPosAreLoaded"
              [numberToIterate]="5"></app-table-skeleton>
            <ng-container *ngIf="openPosAreLoaded">
              <div class="table-responsive"
                *ngIf="poService.openPurchaseOrderList | async; let model">
                <kendo-grid [kendoGridBinding]="model"
                  [style.maxHeight.px]="600"
                  filterable="false"
                  [sortable]="true"
                  [resizable]="true"
                  style="min-width:1200px;">
                  <kendo-grid-column field="one"
                    title=""
                    [width]="60"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <a class="btn btn-xs btn-light"
                        [routerLink]="['/purchase-orders/details', dataItem.poid]"><i class="fa fa-search"></i></a>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="ponumberDisplay"
                    title="PO#"
                    [width]="90">
                  </kendo-grid-column>
                  <kendo-grid-column field="podate"
                    title="Date"
                    [width]="90">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      {{dataItem.podate | date:'shortDate'}}
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="description"
                    title="Description">
                  </kendo-grid-column>
                  <kendo-grid-column field="vendorName"
                    title="Vendor"
                    [width]="200">
                  </kendo-grid-column>
                  <kendo-grid-column field="total"
                    title="Total"
                    [width]="120">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      {{dataItem.total | currency}}
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="derivedStatusName"
                    title="Status"
                    [width]="150">
                  </kendo-grid-column>
                  <kendo-grid-column field="createdByName"
                    title="Created By"
                    [width]="120">
                  </kendo-grid-column>
                </kendo-grid>
              </div>
            </ng-container>
            <!-- END TABLE -->
          </div>
          <!-- END CARD -->
        </div>
      </div>
    </div>
  </div>
</div>
