import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ChooseWorksheetModalComponent } from 'src/app/worksheets/choose-worksheet-modal/choose-worksheet-modal.component';
import { WorksheetModalComponent } from 'src/app/worksheets/worksheet-modal/worksheet-modal.component';
import { WorksheetSnapshotComparisonModalComponent }
  from 'src/app/worksheets/worksheet-snapshot-comparison-modal/worksheet-snapshot-comparison-modal.component';
import { JobTypes } from 'src/enums/job-types';
import { UserModel } from 'src/models/user.model';
import { WorksheetModel } from 'src/models/worksheet.model';
import { AppStateService } from 'src/services/app-state.service';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';
import { UserService } from 'src/services/user.service';
import { WorksheetService } from 'src/services/worksheet.service';

@Component({
  selector: 'app-packer-table',
  templateUrl: './packer-table.component.html',
  styleUrls: ['./packer-table.component.css']
})

export class PackerTableComponent implements OnInit, OnDestroy {
  @Input() showExtendedControls: boolean = true;
  @Output() updatedShowOnJobEvent = new EventEmitter();
  public showOnJob: boolean = false;
  public userIsBuilderOnly: boolean = false;
  public JobTypes = JobTypes;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private userSub: Subscription;
  private deleteSub: Subscription;

  constructor(
    private modalService: BsModalService,
    public kGridService: KGridService,
    public worksheetService: WorksheetService,
    public uiService: UIService,
    public appStateService: AppStateService,
    public userService: UserService
  ) {
    this.allData = this.allData.bind(this);
  };

  public bsModalRef: BsModalRef;

  ngOnDestroy() {
    this.kGridService.resetKGridParams();

    this.isTabletSub?.unsubscribe();

    this.deleteSub?.unsubscribe();

    this.userSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.determineIsTablet();
    this.determineIfUserIsBuilderOnly();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public determineIfUserIsBuilderOnly(): void {
    this.userSub = this.userService.user
      .subscribe((user: UserModel) => {
        this.userIsBuilderOnly = user
          && user.activeRoles
          && user.activeRoles.length === 1
          && user.activeRoles.includes('Builder');
      });
  }

  public deleteWorksheet(ws: WorksheetModel): void {
    this.deleteSub = this.worksheetService.deleteWorksheet(
      ws,
      null,
      this.showOnJob)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Worksheet Deleted');
        } else {
          this.uiService.showError('Error', 'Worksheet Not Deleted');
        }
      });
  }

  public openChooseWorksheetModal(): void {
    const modalSize = this.isTablet ? 'modal-xl' : 'modal-lg';

    const initialState = {
      jobId: null,
      packersOnly: true,
      showOnJob: this.showOnJob
    };

    this.bsModalRef = this.modalService.show(ChooseWorksheetModalComponent, { initialState, class: modalSize, backdrop: 'static' });
  }

  public openWorksheetModal(worksheetId: number): void {
    const initialState = {
      id: worksheetId,
      showOnJob: this.showOnJob
    };
    this.bsModalRef = this.modalService.show(WorksheetModalComponent, { initialState, class: 'modal-xl', backdrop: 'static' });
  }

  public openSnapshotModal(worksheetId: number): void {
    const initialState = {
      worksheetId: worksheetId,
      jobNumber: null
    };
    this.bsModalRef = this.modalService.show(WorksheetSnapshotComparisonModalComponent, { initialState, class: 'modal-xl', backdrop: 'static' });
  }

  public onChangeOnJob($event: boolean): void {
    this.showOnJob = $event;
    this.worksheetService.refreshPackerList(this.showOnJob, false);
    this.updatedShowOnJobEvent.next($event);
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      blue: context.dataItem.jobId,
    };
  }

  public allData(): ExcelExportData {
    let data = this.worksheetService.packerList.value;

    const result: ExcelExportData = {
      data: process(data, { group: null, sort: null }).data,
      group: null
    };

    return result;
  }

}
