import { AddressModel } from './address.model';

export class VendorModel {
  vendorId: number;
  name: string;
  isActive: boolean;
  paymentTermId: number;
  paymentTermName: string;

  vendorAddresses: Array<AddressModel>;
  cityName: string;
  stateName: string;
}
